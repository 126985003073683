<form [formGroup]="form">
    <div class="flex items-center w-[800px] xl:w-[900px] 2xl:w-[1000px] justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Add new income</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="modalService.close()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-row w-full items-start justify-center gap-4">
                <div class="flex flex-col w-full items-start justify-center gap-[16px]">
                    <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">EXPENSE DETAILS</p>
                    <div class="flex flex-col w-full">
                        @if (form.get('name')?.touched && form.get('name')?.invalid) {
                            <label for="name" class="text-lightRed font-regular text-[12px]">Name (Required)</label>
                        } @else {
                            <label for="name" class="text-primaryBlack/70 font-regular text-[12px]">Name</label>
                        }
                        <input formControlName="name" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Expense name"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('date')?.touched && form.get('date')?.invalid) {
                            <label for="date" class="text-lightRed font-regular text-[12px]">Creation date (Required)</label>
                        } @else {
                            <label for="date" class="text-primaryBlack/70 font-regular text-[12px]">Creation date</label>
                        }
                        <input formControlName="date" id="date" type="date" class="placeholder:italic w-full max-h-[200px] rounded-md bg-white border-[1px] border-primaryBlack/20 min-h-[45px] text-primaryBlack focus:outline-lightGreen" placeholder="Creation date">
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('amount')?.touched && form.get('amount')?.invalid) {
                            <label for="amount" class="text-lightRed font-regular text-[12px]">Amount (Required, greater than 0)</label>
                        } @else {
                            <label for="amount" class="text-primaryBlack/70 font-regular text-[12px]">Amount</label>
                        }
                        <input formControlName="amount" type="number" min="0" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Amount"/>
                    </div>
                    <div class="flex flex-col w-full gap-2">
                        <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">Select payment type</p>
                        <div class="flex flex-row items-center gap-4">
                            <label class="flex items-center gap-2">
                                <input type="radio" formControlName="frequencyType" [value]="'Single'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">Single</span>
                            </label>
                            <label class="flex items-center gap-2">
                                <input type="radio" formControlName="frequencyType" [value]="'Monthly'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">Monthly</span>
                            </label>
                            <label class="flex items-center gap-2">
                                <input type="radio" formControlName="frequencyType" [value]="'Yearly'" class="h-4 w-4">
                                <span class="text-[14px] text-primaryBlack">Yearly</span>
                            </label>
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('cycles')?.touched && form.get('cycles')?.invalid) {
                            <label for="cycles" class="text-lightRed font-regular text-[12px]">Cycles (Required)</label>
                        } @else {
                            <label for="cycles" class="text-primaryBlack/70 font-regular text-[12px]">Cycles</label>
                        }
                        <input formControlName="cycles" type="number" min="0" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Cycles"/>
                    </div>
                    <div class="flex flex-col w-full">
                        @if (form.get('paymentDate')?.touched && form.get('paymentDate')?.invalid) {
                            <label for="paymentDate" class="text-lightRed font-regular text-[12px]">Payment date (Required)</label>
                        } @else {
                            <label for="paymentDate" class="text-primaryBlack/70 font-regular text-[12px]">Payment date</label>
                        }
                        <input formControlName="paymentDate" type="date" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Payment date"/>
                    </div>
                </div>
            </div> 
            <div class="flex w-full justify-end">
                <button [disabled]="!form.valid" (click)="saveExpense()" type="submit"
                        [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': !form.valid, 
                                    'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': form.valid}"
                        class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s] font-medium text-[16px]">
                    Save expense
                </button>
            </div>
        </div>
    </div>
</form>
