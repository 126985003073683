import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EmployeeService } from '../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgToastService } from 'ng-angular-popup';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePictureModalComponent } from '../modals/change-picture-modal/change-picture-modal.component';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ActivityService } from '../../services/activity.service';

@Component({
  selector: 'app-user-info-card',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './user-info-card.component.html',
  styleUrl: './user-info-card.component.css'
})
export class UserInfoCardComponent implements OnInit{
  @Input() firstName? : string = '';
  @Input() lastName? : string = '';
  @Input() email? : string = '';
  @Input() emergencyContact? : string = '';
  @Input() dateOfBirth : string = '';
  @Input() imagePath : any;
  @Input() bio? : any;
  @Input() employeeId: string = '';
  @Input() atEmployeeProfilePage : boolean = false;
  @Input() role : string = '';
  @Input() defaultPicture : boolean = true;
  @Input() employeeProfile : boolean = false;

  @Output() bioEdited = new EventEmitter<string>();
  @Output() imageChanged = new EventEmitter<string>();

  public editingBio : boolean = false;
  public tmpBio : string = "";

  public editingPersonal : boolean = false;
  public dotedOpened : boolean = false;

  dateToDisplay : string = ''

  public hasPicture : boolean = true;
  public employeePictureLink : any;
  
  constructor(private employeeService : EmployeeService,
    private toast : NgToastService,
    private modalService : NgbModal,
    private routes : ActivatedRoute,
    private sanitizer: DomSanitizer,
    private activityService : ActivityService
  ){}

  ngOnInit(): void { 
    this.getEmployeePicture();
    if(this.dateOfBirth){
      const date = new Date(this.dateOfBirth);
      this.dateToDisplay = date.toISOString().substring(0,10)
    }  
  }

  public getEmployeePicture() {
    const company = sessionStorage.getItem("company");
    let param;
    let companyId;
    if(this.defaultPicture) {
      param = "default";
      companyId = "firmegeneralepictures"
    } else {
      param = this.employeeId
      companyId = company;
    }

    if(!this.employeeProfile){
      this.employeeService.getEmployeeImage(param, companyId).subscribe(
        (response : string) => {
          this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);;
          this.hasPicture = true;
        },
        (error : HttpErrorResponse) => {
          this.hasPicture = false;
          console.log("Error while getting s3 image link, ", error.message);
        }
      )
    } else {
      this.employeeService.getUserProfilePicutre().subscribe(
        (response : string) => {
          this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);;
          this.hasPicture = true;
        },
        (error : HttpErrorResponse) => {
          this.hasPicture = false;
          console.log("Error while getting s3 image link, ", error.message);
        }
      )
    }
  }

  public toggleEdit(): void {
    this.editingBio = !this.editingBio;
    this.tmpBio = this.bio;
  }

  public toggleEditPersonal(): void {
    this.editingPersonal = !this.editingPersonal;
    this.tmpBio = this.bio;
  }

  public toggleDotedOpened(): void {
    this.dotedOpened = !this.dotedOpened;
  }

  public saveBio(): void {
    const newbio = this.tmpBio;
    console.log(newbio);
    this.editingBio = false;
    const body = {
      field : "Bio",
      value : newbio
    }
    this.employeeService.editEmployeeProfile(body, this.employeeId).subscribe(
      (response : any) => {
        console.log("Bio updated");
        this.bioEdited.emit("Bio");
      },
      (error : HttpErrorResponse) => {
        console.log("Error while editing bio, ", error.message);
        this.toast.danger("Error while editing bio", "Error!", 3000);
      }
    )
  }

  public cancelEdit(): void {
    this.editingBio = false;
  }

  public cancelEditPersonal(): void {
    this.editingPersonal = false;
  }

  public openChangePictureModal() {
    this.goToChangePicture();
  }

  public goToChangePicture(): void {
    const modalRef = this.modalService.open(
      ChangePictureModalComponent,
      { backdrop: 'static', keyboard: true }
    );
    modalRef.componentInstance.userEmail = this.email;
    modalRef.componentInstance.userId = this.employeeId;
    modalRef.componentInstance.imageUploaded.subscribe(
      (res : any) => {
        this.imageChanged.emit("Profile photo");
        // this.getEmployeePicture();
        this.toggleEdit();
        window.location.reload();
      }
    )
  }

  public generateActivityReport() : void {
    this.activityService.getAllForEmployee(this.employeeId).subscribe(
      (response : any) => {
        console.log("ACTIVITIJI: ", response)
        const activities : any[] = response;
        this.activityService.reportPdf(this.employeeId, activities).subscribe(
          (res : any) => {
            console.log(res);
          }
        )
      },
      (error : HttpErrorResponse) => {
        console.log("ERROR", error);
      }
    )
  }
}
