import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from '../../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-change-picture-modal',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './change-picture-modal.component.html',
  styleUrl: './change-picture-modal.component.css'
})
export class ChangePictureModalComponent implements OnInit{

  @Input() userId : string = '';
  @Input() userEmail : string = '';

  @Output() imageUploaded = new EventEmitter<void>();

  imageSrc: string | ArrayBuffer | null = null;
  selectedFile: File | null = null;

  constructor(private modalService : NgbActiveModal,
    private employeeService : EmployeeService
  ){}

  ngOnInit(): void {}

  public closeModal() {
    this.modalService.close();
  }

  public onPictureSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    if (element.files && element.files.length > 0) {
      this.selectedFile = element.files[0];
      const reader = new FileReader();
      
      reader.onload = () => {
        this.imageSrc = reader.result;
      };
  
      reader.readAsDataURL(this.selectedFile);
    } else {
      this.imageSrc = null;
      this.selectedFile = null;
    }
  }
  
  public savePicture(): void {
    if (this.selectedFile && this.userId) {
      const formData = new FormData();
      formData.append('employeeID', this.userId);
      formData.append('image', this.selectedFile);
  
      formData.forEach((value, key) => {
        console.log(key + ' : ' + value);
        if (key === 'image') {
          console.log('File details:', value);
        }
      });
  
      this.employeeService.uploadProfileImage(formData).subscribe(
        (response: any) => {
          console.log('Image upload response:', response);
          this.imageUploaded.emit();
          this.closeModal();
        },
        (error: HttpErrorResponse) => {
          console.error('Error while uploading image:', error.message);
        }
      );
    } else {
      console.error('Please fill all the fields');
    }
  }
  
  
  
}
