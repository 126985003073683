<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto font-poppins gap-4 p-2">
    <div class="flex flex-row w-full justify-between items-center">
        <p class="text-[24px] font-semibold text-primaryBlack">Dashboard</p> 
        <app-button
            [primary]=false
            [secondary]=true
            text="Add new widget"
            width="100%"
            icon="../../../assets/icons/PlusIcon.png"
            [function]="openAddWidgetModal.bind(this)"
        />
    </div>
    <div class="flex flex-row w-full gap-4 h-full items-start justify-start">
        <app-cashflow-widget class="w-[30%] h-full"/>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-6 gap-4">
            @if(filteredWidgets.length === 0){
                <div class="flex w-full col-span-full">
                    <p class="text-primaryBlack font-semibold text-[24px]">
                        There are no widgets to display. <br>
                        <span class="font-light text-[16px]">You can create your new widget by pressing the <span class="font-medium text-lightGreen">"Add new widget"</span> button.</span>
                    </p>
                </div>
            } @else {
                @for (widget of filteredWidgets; track $index) {
                    @if (widget.Name == 'ProjectsFilteredByParameters' && widget.Company == companyId) {
                        <app-projects-widget class="col-span-4"
                            [paramsString]="widget.Querry"
                            [description]="widget.Description"
                            [widgetId]="widget.ID"
                            (widgetDeleted)="handleWidgetDeleted()"
                        />
                    }
                    @if (widget.Name == 'GetOverallBudget' && widget.Company == companyId) {
                        <app-total-budget-widget class="col-span-2"
                            [paramsString]="widget.Querry"
                            [description]="widget.Description"
                            [widgetId]="widget.ID"
                            (widgetDeleted)="handleWidgetDeleted()"
                        />
                    }
                }
            }
        </div>
    </div>
</div>