import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CashflowService } from '../../../services/cashflow.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-expense-modal',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './create-expense-modal.component.html',
  styleUrl: './create-expense-modal.component.css'
})
export class CreateExpenseModalComponent implements OnInit{

  @Output() expenseCreated = new EventEmitter<void>();

  public form! : FormGroup;

  constructor(public modalService : NgbActiveModal,
    private fb : FormBuilder,
    private cashflowService : CashflowService
  ){}

  ngOnInit(): void {
    this.initializeForm();
  }

  public initializeForm() : void {
    this.form = this.fb.group({
      name : ['', Validators.required],
      amount : [0, [Validators.required, Validators.min(0)]],
      date : ['', Validators.required],
      frequencyType : ['', Validators.required],
      cycles : [0, Validators.required],
      paymentDate : ['', Validators.required]
    });
  }

  public saveExpense() : void {
    if(this.form.valid) {
      const formValue = this.form.value;

      const date = new Date(formValue.date);
      const creationDateInMillis = date.getTime();

      const paymentDate = new Date(formValue.paymentDate);
      const paymentDateInMillis = paymentDate.getTime();

      const body = {
        name : formValue.name,
        amount : formValue.amount,
        date : creationDateInMillis,
        frequency : formValue.frequencyType,
        cycles : formValue.cycles,
        ccyId : "66c87c8306f663adac2f29e9",
        paymentDate : paymentDateInMillis
      }

      console.log(body);

      this.cashflowService.saveExpense(body).subscribe(
        (response : any) => {
          console.log(response);
          this.expenseCreated.emit();
          this.modalService.close();
        },
        (error : HttpErrorResponse) => {
          console.log("Error while creating new expense, ", error.message);
        }
      )
    }
  }

}
