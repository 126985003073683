import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { Observable, tap } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { SignInComponent } from '../adminPages/sign-in/sign-in.component';
import { SignUpComponent } from '../adminPages/sign-up/sign-up.component';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [SignInComponent, SignUpComponent],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.css'
})
export class LandingComponent implements OnInit{

  public loggedWithoutCompany : boolean = true;
  public loggedUser : any = false;
  public signIn : boolean = true;
  public signUp : boolean = false;

  constructor(public navigationService : NavigationService,
    private authService : AuthService
   ) {}

  ngOnInit(): void {
    this.authService.authStatus$.subscribe(
      status => {
        this.loggedWithoutCompany = status;
        if(this.loggedWithoutCompany) {
          this.fetchUser();
        }
      }
    )
  }

  public fetchUser() : void {
    const domain = sessionStorage.getItem('domain')
    this.authService.getUserInfo(domain).subscribe(
      (response : any) => {
        this.loggedUser = response;
      },
      (error : HttpErrorResponse) => {
        console.log('Error while fetching user, ', error.message);
      }
    )
  }

  public navigateToSignUp() {
    this.signIn = false;
    this.signUp = true;
  }

  public navigateToSignIn() {
    this.signIn = true;
    this.signUp = false;
  }
}
