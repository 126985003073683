import { Component, Input, OnInit } from '@angular/core';
import { Project } from '../../models/project';
import { Status } from '../../models/project-status';
import { ProjectCardComponent } from '../project-card/project-card.component';
import { ButtonComponent } from '../button/button.component';
import { EmployeeService } from '../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-user-projects',
  standalone: true,
  imports: [ProjectCardComponent, ButtonComponent],
  templateUrl: './user-projects.component.html',
  styleUrl: './user-projects.component.css'
})
export class UserProjectsComponent implements OnInit{

  public employeeId : string = ''
  public engagements : any[] = [];

  constructor(private employeeService : EmployeeService,
    private routes : ActivatedRoute,
    public navigationService : NavigationService,
  ){}

  ngOnInit(): void {
    const idFromRoute = this.routes.snapshot.paramMap.get('id');
    if(idFromRoute != null){
      this.employeeId = idFromRoute;
    }    
    this.getAllEngagements();
  }

  public getAllEngagements() {
    this.employeeService.getEngagements(this.employeeId).subscribe(
      (response : any) => {
        this.engagements = response;
      },
      (error: HttpErrorResponse) => {
        console.log("Error while fetching engagements for employee, ", error.message);
      }
    )
  }
}
