<div [ngClass]="{'border-r-[1px] border-text-primaryBlack' : !isAdmin && !lightText}" 
    class="flex flex-col font-poppins w-full items-center justify-center justify-between h-full">
    <div class="flex flex-col w-full h-full gap-4">
        <div class="flex flex-row w-full items-center justify-between">
            <div class="flex flex-row items-center">
                <p class="font-bold text-lightGreen text-[24px]">Firme</p>
                <p class="ml-[5px] font-light text-white text-[24px]">Générale</p>
            </div>
        </div>
        @if(userLogged) {
            <div class="flex flex-col w-full h-full gap-4">
                @if (isAdmin) {
                    @if (haveCompany && companyChoosen){
                        <div class="flex flex-col h-auto w-full relative">
                            <app-global-search/>
                        </div>
                        <div class="flex flex-col w-full">
                            <p class="font-light text-[12px] 2xl:text-[16px] text-lightGrey/50 tracking-widest">COMPANY</p>
                            <!-- <div class="relative flex flex-row items-center  w-full">
                                <button id="dropdownActionButton" data-dropdown-toggle="dropdownAction" 
                                        class="p-2 group hover:bg-lightGreen rounded-full transition-[0.5s]" 
                                        type="button" *ngIf="userLogged && isAdmin && companyChoosen && haveCompany"
                                        (click)="openChooseCompanyPage()">
                                    <img src="../../../assets/icons/ChooseIcon.png" class="nav_menu_icon size-[20px] 2xl:size-[25px] opacity-40 group-hover:opacity-100 transition-[0.5s]"/>
                                </button>
                                <p class="text-primaryWhite text-[14px] 2xl:text-[18px]">{{companyName}}</p>>
                            </div> -->
    
                            <a [routerLink]="['/choose']">
                                <div class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="openChooseCompanyPage()">
                                    <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                        <img src="../../../assets/icons/ChooseIcon.png"
                                            class="size-[20px] 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    </div>
                                    <div class="flex flex-col max-w-[250px]">
                                        <p class="text-white/70 group-hover:text-white font-medium ml-4 text-[16px] 2xl:text-[20px] truncate">{{companyName}}</p>
                                        <!-- <p class="text-white/70 group-hover:text-white font-light ml-4 text-[12px] 2xl:text-[16px]">Tap to change</p> -->
                                        @if(numberOfCompanies > 1){
                                            <p class="text-white/70 group-hover:text-white font-light ml-4 text-[12px] 2xl:text-[16px]">Tap to change</p>
                                        } @else {
                                            <p class="text-white/70 group-hover:text-white font-light ml-4 text-[12px] 2xl:text-[16px]">Tap to add</p>
                                        }
                                    </div>
                                </div> 
                            </a>
                        </div>
    
                        <div class="flex flex-col gap-2">
                            <p class="font-light text-[12px] 2xl:text-[16px] text-lightGrey/50 tracking-widest">NAVIGATION</p>
                            <a [routerLink]="['/dashboard']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Dashboard',
                                                '': activeWindow !== 'Dashboard'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Dashboard')">
                                    <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                        <img src="../../../assets/icons/DashboardIcon.png"
                                            [ngClass]="{'opacity-100' : activeWindow === 'Dashboard',
                                                        'opacity-50' : activeWindow !== 'Dashboard'}"
                                            class="size-[20px] 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    </div>
                                    <p [ngClass]="{'text-white' : activeWindow === 'Dashboard',
                                                    'text-white/70' : activeWindow !== 'Dashboard'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Dashboard</p>
                                </div> 
                            </a>
        
                            <a [routerLink]="['/employees']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Employees',
                                                '': activeWindow !== 'Employees'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Employees')">
                                    <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                        <img src="../../../assets/icons/EmployeesIcon.png"
                                            [ngClass]="{'opacity-100' : activeWindow === 'Employees',
                                                        'opacity-50' : activeWindow !== 'Employees'}"
                                            class="size-[20px] 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    </div>
                                    <p [ngClass]="{'text-white' : activeWindow === 'Employees',
                                                    'text-white/70' : activeWindow !== 'Employees'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Employees</p>
                                </div> 
                            </a>
        
                            <a [routerLink]="['/projects']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Projects',
                                                '': activeWindow !== 'Projects'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Projects')">
                                    <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                        <img src="../../../assets/icons/ProjectsIcon.png"
                                            [ngClass]="{'opacity-100' : activeWindow === 'Projects',
                                                        'opacity-50' : activeWindow !== 'Projects'}"
                                            class="size-[20px] 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    </div>
                                    <p [ngClass]="{'text-white' : activeWindow === 'Projects',
                                                    'text-white/70' : activeWindow !== 'Projects'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Projects</p>
                                </div>
                            </a> 

                            <a [routerLink]="['/analytics']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Finance',
                                                '': activeWindow !== 'Finance'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Finance')">
                                    <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                        <img src="../../../assets/icons/AnalitycsIcon.png"
                                            [ngClass]="{'opacity-100' : activeWindow === 'Finance',
                                                        'opacity-50' : activeWindow !== 'Finance'}"
                                            class="size-[20px] 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    </div>
                                    <p [ngClass]="{'text-white' : activeWindow === 'Finance',
                                                    'text-white/70' : activeWindow !== 'Finance'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Finance</p>
                                </div>
                            </a> 
        
                            <!-- <div class="flex flex-row w-full items-center cursor-not-allowed group rounded-full  p-2 transition-[1s]">
                                <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                    <img src="../../../assets/icons/AnalitycsIcon.png" class="size-[20px] 2xl:size-[30px] nav_menu_icon opacity-50 transition-[0.7s]">
                                </div>
                                <p class="text-white/70 ml-4 text-[18px] 2xl:text-[24px]">Analytics</p>
                            </div>  -->
        
                            <a [routerLink]="['/inventory']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Assets',
                                                '': activeWindow !== 'Assets'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Assets')">
                                    <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                        <img src="../../../assets/icons/AssetsIcon.png"
                                            [ngClass]="{'opacity-100' : activeWindow === 'Assets',
                                                        'opacity-50' : activeWindow !== 'Assets'}"
                                            class="size-[20px] 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    </div>
                                    <p [ngClass]="{'text-white' : activeWindow === 'Assets',
                                                    'text-white/70' : activeWindow !== 'Assets'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Equipment</p>
                                </div>
                            </a>   
                            
                            <a [routerLink]="['/settings']">
                                <div [ngClass]="{'bg-lightGrey/20' : activeWindow === 'Settings',
                                                '': activeWindow !== 'Settings'}" 
                                    class="flex flex-row w-full items-center hover:opacity-100 cursor-pointer group rounded-full hover:bg-lightGrey/20 p-2 transition-[1s]"
                                    (click)="setActiveWindow('Settings')">
                                    <div class="flex size-[35px] 2xl:size-[45px] rounded-full items-center justify-center bg-lightGrey/30">
                                        <img src="../../../assets/icons/SettingsIcon.png"
                                            [ngClass]="{'opacity-100' : activeWindow === 'Settings',
                                                        'opacity-50' : activeWindow !== 'Settings'}"
                                            class="size-[20px] 2xl:size-[30px] nav_menu_icon opacity-50 group-hover:opacity-100 transition-[0.7s]">
                                    </div>
                                    <p [ngClass]="{'text-white' : activeWindow === 'Settings',
                                                    'text-white/70' : activeWindow !== 'Settings'}"
                                        class="text-white/70 group-hover:text-white ml-4 text-[18px] 2xl:text-[24px]">Settings</p>
                                    @if (notifyForChanges) {
                                        <div class="size-[15px] ml-4 rounded-full bg-lightYellow"></div>
                                    }
                                </div>
                            </a>
                        </div>
                    } 
                } 
            </div>
        } 
    </div>
    @if (userLogged) {
        <div [routerLink]="'/myProfile'"  class="flex flex-col w-full gap-2">
            <p [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28'}"
                class="font-light text-[12px] 2xl:text-[16px] tracking-widest opacity-50">USER PROFILE</p>
            <div class="flex flex-row w-full items-center justify-start justify-between">
                <div class="flex flex-row items-center">
                    <div class="flex flex-col items-start gap-1">
                        <p [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28'}"
                            class="font-medium text-[12px] 2xl:text-[18px]">{{fullName}}</p>
                        <p [ngStyle]="{'color' : lightText ? '#F5F6FA' : '#282B28'}"
                            class="font-light sm:text-[8px] xl:text-[9px] 2xl:text-[12px]">{{email}}</p>
                    </div>
                </div>
                <div (click)="logout()"
                    class="flex items-center justify-center rounded-full size-[40px] p-2 transition-[0.5s] hover:bg-primaryBlack/50 group cursor-pointer">
                    <img src="../../../assets/icons/SignOutIcon.png"
                        [ngClass]="{'nav_menu_icon' : lightText}"
                        class="opacity-50 group-hover:opacity-100 transition-[0.7s]">
                </div>
            </div>
        </div>
    }
</div>