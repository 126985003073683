import { Component, OnInit, TemplateRef } from '@angular/core';
import { EmployeeCardComponent } from '../../../components/employee-card/employee-card.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEmployeeModalComponent } from '../../../components/modals/add-employee-modal/add-employee-modal.component';
import { EmployeeService } from '../../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ButtonComponent } from '../../../components/button/button.component';
import { NgToastService } from 'ng-angular-popup';
import { FormsModule } from '@angular/forms';
import { SkeletonEmployeeCardComponent } from '../../../components/loaders/skeleton-employee-card/skeleton-employee-card.component';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-employees',
  standalone: true,
  imports: [EmployeeCardComponent, ButtonComponent, FormsModule, SkeletonEmployeeCardComponent, CommonModule],
  templateUrl: './employees.component.html',
  styleUrl: './employees.component.css'
})
export class EmployeesComponent implements OnInit{

  companyId : any = sessionStorage.getItem('company');

  public isLoading : boolean = true;
  public employeesCount = new Array(12);

  public filterOwners : boolean = false;
  public filterManagers : boolean = false;
  public filterEmployee : boolean = false;
  public filterAdmin : boolean = false;

  public employees : any[] = [];
  public filteredEmployees: any[] = [];
  public searchText: string = '';

  constructor(private modalService : NgbModal,
    private employeeService : EmployeeService,
    private toast : NgToastService,
    private authService : AuthService
  ){
    // this.authService.$refreshTokenReceived.subscribe(
    //   (res : any) => {
    //     this.loadEmployees();
    //   }
    // )
  }

  ngOnInit(): void {
    this.loadEmployees();
  }

  public loadEmployees() : void {
    this.isLoading = true;
    this.filteredEmployees = [];
    this.employeeService.getAll().subscribe(
      (response : any[]) => {
        if(response) {
          this.employees = response.map(employee => {
            const requiresEditing = employee.Skills.some((skill: { Level: string; }) => skill.Level === "000000000000000000000000");
            return {
              ...employee, 
              FullName: employee.FirstName + " " + employee.LastName,
              requiresEditing
            };
          });
          this.filteredEmployees = this.employees
          this.isLoading = false;
          console.log(this.employees);
        } else {
          this.isLoading = false;
        }
      },
      (error : HttpErrorResponse) => {
        this.isLoading = false;
        console.log("Error while fetching employees: ", error.message);
      }
    );  
  }


  public searchEmployees(): void {
    if (!this.searchText) {
      this.filteredEmployees = this.employees;
    } else {
      this.filteredEmployees = this.employees.filter(employee =>
        employee.FullName.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
  }

  public filterEmployees() : void {
    this.filteredEmployees = [];
    if(this.filterAdmin || this.filterEmployee || this.filterManagers || this.filterOwners) {
      this.filteredEmployees = this.employees.filter(employee => 
        (this.filterAdmin && employee.Role === 'Admin') || (this.filterEmployee && employee.Role === 'Employee') ||
        (this.filterManagers && employee.Role === 'Project Manager') || (this.filterOwners && employee.Role === 'Owner')
      );
    } else {
      this.filteredEmployees = this.employees;
      console.log(this.filteredEmployees);
    }
  }

  openAddEmployeeModal() {
    this.goToAddEmployee();
  }

  public goToAddEmployee(): void {
    const modalRef = this.modalService.open(
      AddEmployeeModalComponent,
      { backdrop: 'static', keyboard: true }
    );
    modalRef.componentInstance.employeeAdded.subscribe(
      (response : any) => {
        this.loadEmployees();
        this.toast.success("Employee created successfully!", "Success!", 3000)
      },
      (error : Error) => {
        this.toast.danger("An error occurred when creating an employee!", "Error!", 3000)
      }
    );
  }

  trackByEmployeeId(index: number, employee: any): any {
    return employee.EmployeeID;
  }
}
