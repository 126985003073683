import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../button/button.component';
import { CreatePositionModalComponent } from '../create-position-modal/create-position-modal.component';
import { CompanyService } from '../../../services/company.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectService } from '../../../services/project.service';
import { NgToastService } from 'ng-angular-popup';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { SelectProjectManagerModalComponent } from '../select-project-manager-modal/select-project-manager-modal.component';
import { ErrorResponse } from '../../../dtos/ErrorResponse';

@Component({
  selector: 'app-create-project-modal',
  standalone: true,
  imports: [FormsModule, ButtonComponent, ReactiveFormsModule, CommonModule],
  templateUrl: './create-project-modal.component.html',
  styleUrl: './create-project-modal.component.css'
})
export class CreateProjectModalComponent implements OnInit{

  @Output() projectCreated = new EventEmitter<void>();

  form! : FormGroup;
  
  projectName : string = '';
  managerId : string = '';
  budget : number = 0;
  startDate : string = '';
  projectDuration : number = 0;
  positions : any[] = [];
  projectPositionsForBody : any[] = [];
  projectManagerSelected : boolean = false;
  projectManager : any;

  fetchedCompany : any;

  constructor(private activeModalService : NgbActiveModal,
    private modalService : NgbModal,
    private authService : AuthService,
    private toast : NgToastService,
    private projectService : ProjectService,
    private fb : FormBuilder,
    private companyService : CompanyService){}

  ngOnInit(): void {
    this.subscribeToSkills();
    this.fetchCompany();
    this.initializeFormGroup();
  }

  public initializeFormGroup() : void {
    this.form = this.fb.group({
      ProjectName: ['', Validators.required],
      Budget: [0, [Validators.required, Validators.min(0)]],
      StartDate: ['', Validators.required],
      Duration: [0, [Validators.required, Validators.min(0)]],
      Description : ''
    });
  }
 
  public fetchCompany() : void {
    const companyId = sessionStorage.getItem('company');

    this.companyService.getCompany().subscribe(
      (response : any) => {
        this.fetchedCompany = response;
        console.log(this.fetchedCompany);
      },
      (error : HttpErrorResponse) => {
        console.log("Error while fetching company, ", error.message);
      }
    )
  }


  public selectProjectManager() : void {
    const modalRef = this.modalService.open(
      SelectProjectManagerModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.projectManagerSelected.subscribe(
      (res : any) => {
        this.projectManager = res;
        this.projectManagerSelected = true;
        console.log("PROJECT MANAGER: ", this.projectManager)
      },
      (error : ErrorResponse) => {
        console.log("Error while selecting project manager, ", error.Message);
        this.projectManagerSelected = false;
      }
    );
  }

  openCreatePositionModal() {
    this.goToCreatePosition();
  }

  public goToCreatePosition(): void {
    const modalRef = this.modalService.open(
      CreatePositionModalComponent,
      { backdrop: 'static', keyboard: true },
    );

    modalRef.componentInstance.company = this.fetchedCompany;
  }

  public closeModal() : void {
    this.activeModalService.close()
  }


  private subscribeToSkills(): void {
    this.projectService.createdPositions$.subscribe((position) => {
      this.positions.push(position);
      console.log(position);
      this.toast.success("Skill successfully added!", "Success!", 3000);
    });
  }

  public createProject() : void {
    if(this.form.valid){
      const formValue = this.form.value
      this.projectPositionsForBody = [];
      console.log("PROJECT MANAGER 2: ", this.projectManager)


      this.positions.map((position) => {
        const createdPosition = {
          profession : position.PositionID,
          primarySkill : position.PrimarySkillID,
          skillLevel : position.SkillLevelID,
          employee : "000000000000000000000000",
          cost : position.Cost
        }

        this.projectPositionsForBody.push(createdPosition);
      })

      if(!this.projectManagerSelected) {
        this.managerId = ""
      } else {
        this.managerId = this.projectManager.EmployeeID;
      }
      // const projectStartDate = this.startDate + "T00:00:00Z"
      var date = new Date(formValue.StartDate);
      var startDateInMillis = date.getTime();
      const body = {
        // name : this.projectName,
        // managerID : this.managerId,
        // budget : this.budget,
        // startDate : startDateInMillis,
        // projectDuration : this.projectDuration,
        name : formValue.ProjectName,
        managerID : this.managerId,
        budget : formValue.Budget,
        startDate : startDateInMillis,
        projectDuration : formValue.Duration,
        description : formValue.Description,
        positions : this.projectPositionsForBody
      }

      console.log(body);
      console.log("Created project: ", body);
      this.projectService.create(body).subscribe(
        (response : any) => {
          console.log("Project created successfully", response);
          this.toast.success("Project successfully created!", "Success!", 3000);
          this.projectCreated.emit();
          this.closeModal();
        },
        (error : HttpErrorResponse) => {
          this.toast.danger("Error while creating project!", "Error!", 3000);
        }
      )
    }
  }
  
  public isFormValid() : boolean {
    return this.form.valid;
  }
  
}
