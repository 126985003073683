<form [formGroup]="form">
    <div class="flex items-center w-[900px] xl:w-[1000px] 2xl:w-[1100px] 3xl:w-[1200px] h-auto justify-center bg-primaryBlack/30 font-poppins">
        <div class="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4">
            <div class="flex flex-row w-full items-center justify-center justify-between">
                <p class="text-[24px] font-semibold text-primaryBlack">Create new project</p> 
                <button class="flex size-[30px] rounded-md bg-darkGreen items-center justify-center 
                                hover:bg-lightGreen transition-[0.5s]"
                        (click)="closeModal()">
                    <img src="../../../assets/icons/CloseIcon.png" class="size-[20px] nav_menu_icon">
                </button>        
            </div>
            <div class="flex flex-row w-full items-start justify-center gap-4">
                <div class="flex flex-col w-[40%] items-start justify-start gap-[16px]">
                    <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">PROJECT INFO</p>
                    <!-- <div class="flex flex-col w-full mt-2">
                        <label for="name" class="text-primaryBlack/70 font-regular text-[12px]">Project name</label>
                        <input [(ngModel)]="projectName" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Project name"/>               
                    </div> -->
    
                    <div class="flex flex-col w-full">
                        @if (form.get('ProjectName')?.touched && form.get('ProjectName')?.invalid) {
                            <label for="projectName" class="text-lightRed font-regular text-[12px]">Project name (Required)</label>
                        } @else {
                            <label for="projectName" class="text-primaryBlack/70 font-regular text-[12px]">Project name</label>
                        }
                        <input formControlName="ProjectName" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Project name"/>
                    </div>
    
    
                    <!-- <div class="flex flex-col w-full">
                        <label for="dateInput" class="text-primaryBlack/70 font-regular text-[12px]">Budget</label>
                        <input [(ngModel)]="budget" min="0" type="number" id="dateInput" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                    </div> -->
    
                    <div class="flex flex-col w-full">
                        @if (form.get('Budget')?.touched && form.get('Budget')?.invalid) {
                            <label for="budget" class="text-lightRed font-regular text-[12px]">Budget (Required, must be greater than 0)</label>
                        } @else {
                            <label for="budget" class="text-primaryBlack/70 font-regular text-[12px]">Budget</label>
                        }
                        <input formControlName="Budget" type="number" min="0" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Budget"/>
                    </div>
    
    
                    <!-- <div class="flex flex-col w-full">
                        <label for="dateInput" class="text-primaryBlack/70 font-regular text-[12px]">Start date</label>
                        <input [(ngModel)]="startDate" type="date" id="dateInput" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                    </div> -->
    
                    <div class="flex flex-col w-full">
                        @if (form.get('StartDate')?.touched && form.get('StartDate')?.invalid) {
                            <label for="startDate" class="text-lightRed font-regular text-[12px]">Start date (Required)</label>
                        } @else {
                            <label for="startDate" class="text-primaryBlack/70 font-regular text-[12px]">Start date</label>
                        }
                        <input formControlName="StartDate" type="date" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Start date"/>
                    </div>
    
                    <!-- <div class="flex flex-col w-full">
                        <label for="dateInput" class="text-primaryBlack/70 font-regular text-[12px]">Duration (in days)</label>
                        <input [(ngModel)]="projectDuration" min="0" type="number" id="dateInput" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                    </div> -->
    
                    <div class="flex flex-col w-full">
                        @if (form.get('Duration')?.touched && form.get('Duration')?.invalid) {
                            <label for="duration" class="text-lightRed font-regular text-[12px]">Duration (in days) (Required, must be greater than 0)</label>
                        } @else {
                            <label for="duration" class="text-primaryBlack/70 font-regular text-[12px]">Duration (in days)</label>
                        }
                        <input formControlName="Duration" type="number" min="0" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="Duration"/>
                    </div>

                    <div class="flex flex-col w-full h-full">
                        <label for="duration" class="text-primaryBlack/70 font-regular text-[12px]">Description (optional)</label>
                        <textarea class="flex w-full h-[200px] p-2 rounded-md bg-white border-[1px] text-[12px] border-primaryBlack/20 text-primaryBlack focus:outline-lightGreen" formControlName="Description"></textarea>
                    </div>

                    <div class="flex flex-col w-full">
                        <label for="duration" class="text-primaryBlack/70 font-regular text-[12px]">Project manager</label>
                        @if (managerId === '') {
                            <div class="flex items-center justify-center w-full p-2 rounded-md text-[12px] font-medium border-[1px] 
                                        hover:bg-lightGreen hover:text-primaryWhite transition-[0.5s] uppercase cursor-pointer"
                                (click)="selectProjectManager()">
                                Select manager
                            </div>
                        }
                    </div>
                </div>
                <div class="flex flex-col w-[60%] items-start justify-start gap-4">
                    <div class="flex flex-col w-full gap-2 justify-start">
                        <div class="flex flex-row gap-2 items-center w-full">
                            <p class="text-[14px] text-primaryBlack/50 font-light tracking-wide">POSITIONS</p>
                            <button class="flex rounded-md bg-darkGreen p-2 gap-2 items-center justify-center hover:bg-lightGreen transition-[0.5s]"
                                    (click)="openCreatePositionModal()">
                                <img src="../../../assets/icons/PlusIcon.png" class="size-[15px] nav_menu_icon">
                                <p class="text-[12px] text-primaryWhite">Add new</p>
                            </button>
                        </div>
                        <p class="text-[12px] text-primaryBlack/50 font-light">Add one or more skills for the new employee</p>
                        <hr class="text-primaryBlack/50 w-full">
                    </div>
                    <!-- <div class="flex flex-col w-full max-h-[250px] overflow-y-scroll gap-2">
                        @for (position of positions; track $index) {
                            <div class="flex flex-row w-full justify-center justify-between items-center">
                                <p>{{position.PositionName}}</p>
                                <p>{{position.PrimarySkillName}}</p>
                                <p>{{position.SkillLevelName}}</p>
                            </div>
                        }
                    </div> -->
                    <div class="flex flex-col w-full max-h-[250px] overflow-y-auto">
                        <table class="w-full text-center rounded-md">
                            <thead class="border-[1px] border-b-primaryBlack/20">
                                <tr class="bg-lightGreen/20"> <!-- Možete dodati boju pozadine za zaglavlje -->
                                    <th class="font-medium text-[12px] text-lightGreen px-2 py-2">Position</th>
                                    <th class="font-medium text-[12px] text-lightGreen px-2 py-2">Primary skill</th>
                                    <th class="font-medium text-[12px] text-lightGreen px-2 py-2">Exp. level</th>
                                    <th class="font-medium text-[12px] text-lightGreen px-2 py-2">Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                @for (position of positions; track $index) {
                                    <tr class="border-[1px] border-b-primaryBlack/20">
                                        <td class="font-light text-[10px] text-primaryBlack px-2 py-2 px-4 py-2">{{position.PositionName}}</td>
                                        <td class="font-light text-[10px] text-primaryBlack px-2 py-2 px-4 py-2">{{position.PrimarySkillName}}</td>
                                        <td class="font-light text-[10px] text-primaryBlack px-2 py-2 px-4 py-2">{{position.SkillLevelName}}</td>
                                        <td class="font-light text-[10px] text-primaryBlack px-2 py-2 px-4 py-2">{{position.Cost}}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    
                    
                </div>
            </div>
            <div class="flex w-full justify-end">
                <button [disabled]="!form.valid" (click)="createProject()"
                        [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': !form.valid, 'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': form.valid}"
                        class="flex items-center justify-center px-4 py-2 rounded-md transition-[0.5s] font-medium text-[16px]">
                    Save project
                </button>
            </div>
        </div>
    </div>
</form>