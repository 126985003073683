<div class="flex flex-row w-full gap-4 items center">
    <div class="flex flex-col w-full p-4 bg-primaryWhite rounded-md gap-4">
        <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 tracking-wide">CURRENTLY USING</p>
        @if (currentylInUse.length <= 0) {
            <div class="flex w-full">
                <p class="text-primaryBlack font-semibold text-[24px]">
                    There are no assets currently in use. <br>
                    <span class="font-light text-[16px]">The employee is not currently using any company assets.</span>
                </p>
            </div>
        } @else {
            <div class="flex flex-row justify-start w-full gap-4 items-center overflow-x-auto">
                @for (item of currentylInUse; track $index) {
                    <div class="flex flex-col w-auto p-4 rounded-md border-[1px] border-primaryBlack/20 gap-2  hover:border-lightGreen transition-[0.5s] cursor-default">
                        <h1 class="text-primaryBlack font-medium text-[16px]">{{item.name}}</h1>    
                        <div class="flex flex-col">
                            <p class="text-primaryBlack font-light text-[12px]">Serial number: <span class="font-medium">{{item.serialNumber}}</span></p>
                            <p class="text-primaryBlack font-light text-[12px]">Start date: <span class="font-medium">{{getParsedDate(item.StartDate)}}</span></p>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
    <div class="flex flex-col w-full p-4 bg-primaryWhite rounded-md gap-4">
        <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 tracking-wide">PREVIOUSLY USED ASSETS</p>
        @if (previouslyUsed.length <= 0) {
            <div class="flex w-full">
                <p class="text-primaryBlack font-semibold text-[24px]">
                    There are no assets that have been used. <br>
                    <span class="font-light text-[16px]">The employee has not used any asset.</span>
                </p>
            </div>
        } @else {
            <div class="flex flex-row justify-start w-full gap-4 items-center overflow-x-auto">
                @for (item of previouslyUsed; track $index) {
                    <div class="flex flex-col w-auto p-4 rounded-md border-[1px] border-primaryBlack/20 gap-2 hover:border-lightGreen transition-[0.5s] cursor-default">
                        <h1 class="text-primaryBlack font-medium text-[16px]">{{item.name}}</h1>    
                        <div class="flex flex-col">
                            <p class="text-primaryBlack font-light text-[12px]">Serial number: <span class="font-medium">{{item.serialNumber}}</span></p>
                            <p class="text-primaryBlack font-light text-[12px]">Return date: <span class="font-medium">{{getParsedDate(item.EndDate)}}</span></p>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
</div>