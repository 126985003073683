import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Income } from '../models/income';
import { Expense } from '../models/expense';

@Injectable({
  providedIn: 'root'
})
export class CashflowService {

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http : HttpClient) { }

  public getAllIncomes() : Observable<Income[]>{
    return this.http.get<Income[]>(`${this.apiServerUrl}/income/getFilteredIncomes`);
  }

  public saveIncome(body : any) : Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/income/save`, body);
  }

  public deleteIncome(incomeId : string) : Observable<any> {
    return this.http.delete<any>(`${this.apiServerUrl}/income/delete?id=${incomeId}`);
  }

  public getAllExpenses() : Observable<Expense[]> {
    return this.http.get<Expense[]>(`${this.apiServerUrl}/expense/get/all`);
  }

  public deleteExpense(expenseId : string) : Observable<any> {
    return this.http.delete<any>(`${this.apiServerUrl}/expense/delete/${expenseId}`);
  }

  public saveExpense(body : any) : Observable<any> {
    return this.http.post<any>(`${this.apiServerUrl}/expense/save`, body);
  } 
  
  public getCashFlow() : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/cashflow/getFlow`);
  }

  public getWidgetData() : Observable<any> {
    return this.http.get<any>(`${this.apiServerUrl}/cashflow/`);
  }
}
