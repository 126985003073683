import { Component, Input, OnInit } from '@angular/core';
import { AssetsService } from '../../services/assets.service';
import { AssetHistoryItem } from '../../models/assetHistoryItem';

@Component({
  selector: 'app-employee-asset-history-section',
  standalone: true,
  imports: [],
  templateUrl: './employee-asset-history-section.component.html',
  styleUrl: './employee-asset-history-section.component.css'
})
export class EmployeeAssetHistorySectionComponent implements OnInit{
  
  @Input() employeeId : string = '';

  public assetHistory : AssetHistoryItem[] = [];
  public currentylInUse : AssetHistoryItem[] = [];
  public previouslyUsed : AssetHistoryItem[] = [];

  constructor(private assetService : AssetsService){}

  ngOnInit(): void {
    this.fetchAssetHistoryForEmployee();
  }

  public fetchAssetHistoryForEmployee() : void {
    this.assetService.employeesAssetHistory(this.employeeId).subscribe(
      (response : AssetHistoryItem[]) => {
        console.log("HISTORY: ", response);
        this.assetHistory = response;

        this.assetHistory.forEach(hist => {
          if(hist.EndDate === -1) {
            this.currentylInUse.push(hist);
          } else {
            this.previouslyUsed.push(hist);
          }
        });
      }
    )
  }

  public getParsedDate(dateInMillis : number) : string {
    const date = new Date(dateInMillis);
    const dateParsed = date.toISOString().substring(10,2);
    return dateParsed
  }

}
