import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetsService } from '../../../services/assets.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Asset } from '../../../models/asset';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectService } from '../../../services/project.service';
import { Project } from '../../../models/project';
import { CashflowService } from '../../../services/cashflow.service';

@Component({
  selector: 'app-create-income-modal',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './create-income-modal.component.html',
  styleUrl: './create-income-modal.component.css'
})
export class CreateIncomeModalComponent implements OnInit{

  @Output() incomeAdded = new EventEmitter<void>();

  public form! : FormGroup;
  public showAssets : boolean = false;
  public showProjects : boolean = false;
  public selectedAsset? : Asset;
  public selectedAssetId : string = '';
  public selectedProject? : Project;
  public selectedProjectId : string = '';

  public assets : any[] = [];
  public projects : Project[] = [];

  constructor(public modalService : NgbActiveModal,
    private fb : FormBuilder,
    private assetsService : AssetsService,
    private sanitizer : DomSanitizer,
    private projectService : ProjectService,
    private cashflowService : CashflowService
  ){}

  ngOnInit(): void {
    this.initializeForm();
  }

  public initializeForm() : void {
    this.form = this.fb.group({
      name : ['' , Validators.required],
      description : ['' , Validators.required],
      amount : [0, [Validators.required, Validators.min(0)]],
      payer : ['', Validators.required],
      paymentDate : ['', Validators.required],
      bindingType : ['', Validators.required],
      frequencyType : ['', Validators.required],
      cycles : [0, Validators.required],
    });
  }

  public saveIncome() : void {
    if(this.form.valid) {
      const formValue = this.form.value;

      const date = new Date(formValue.paymentDate);
      const dateInMillis = date.getTime();

      var bindingId;

      if(formValue.bindingType === "Asset") bindingId = this.selectedAssetId;
      if(formValue.bindingType === "Project") bindingId = this.selectedProjectId;
      if(formValue.bindingType === "None") bindingId = '';

      const body = {
        name : formValue.name,
        description : formValue.description,
        ammount : formValue.amount,
        payer : formValue.payer,
        paymentDate : dateInMillis,
        bindingType : formValue.bindingType,
        bindingID : bindingId,
        cycles : formValue.cycles,
        frequency : formValue.frequencyType
      }

      console.log("BODY: ", body);

      this.cashflowService.saveIncome(body).subscribe(
        (response : any) => {
          console.log("Income saved successfully, ", response);
          this.incomeAdded.emit();
          this.modalService.close();
        },
        (error : HttpErrorResponse) => {
          console.log("Error while creating new income, ", error.message);
        }
      )
    }
  }

  public fetchAssets(): void {
    this.assetsService.getAll().subscribe(
      (response: any[]) => {
        this.assets = response;
        if(this.assets !== null && this.assets.length > 0){
          this.assets.forEach(asset => {
            this.getAssetPicture(asset);
          });
        }
        console.log(this.assets);
      },
      (error: HttpErrorResponse) => {
        console.log("Error while fetching assets, ", error.message);
      }
    );
  }

  public fetchProjects() : void {
    this.projectService.getAll().subscribe(
      (response : Project[]) => {
        this.projects = response;
      },
      (error : HttpErrorResponse) => {
        console.log("Error while fetching projects for income, ", error.message);
      }
    )
  }


  public getAssetPicture(asset: any): void {
    const company = sessionStorage.getItem("company");
    let param;
    let companyId;

    if (asset.DefaultPicture) {
      param = "defaultasset";
      companyId = "firmegeneralepictures";
    } else {
      param = asset.ID;
      companyId = company;
    }

    console.log(param);
    console.log(companyId);

    this.assetsService.getAssetImage(param, companyId).subscribe(
      (response: string) => {
        asset.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
      },
      (error: HttpErrorResponse) => {
        console.log("Error while getting S3 image link, ", error.message);
      }
    );
  }

  public toggleSection(section : string) : void {
    if(section === 'Assets') {
      this.showAssets = true;
      this.showProjects = false;
      this.fetchAssets();
    }
    if(section === 'Projects') {
      this.showProjects = true;
      this.showAssets = false;
      this.fetchProjects();
    }
  }

  public onAssetSelected(asset: Asset) : void {
    this.selectedAsset = asset;
    this.selectedAssetId = this.selectedAsset.ID;
    console.log(this.selectedAssetId)
  }

  public isActiveAsset(selectedAsset: Asset): boolean {
    return this.selectedAsset === selectedAsset;
  }

  public onSelectedProject(project: Project) : void {
    this.selectedProject = project;
    this.selectedProjectId = this.selectedProject.ID;
    console.log(this.selectedProjectId)
  }

  public isActiveProject(selectedProject: Project): boolean {
    return this.selectedProject === selectedProject;
  }
}
