<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto p-2 gap-4">
    <div class="flex flex-row w-full justify-between items-center">
        <div class="flex flex-row gap-4 items-center">
            <p class="text-[24px] font-semibold text-primaryBlack">Projects</p> 
            <div class="flex h-[45px] relative">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="text" [(ngModel)]="searchText" (input)="searchProjects()" id="table-search-users" class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-80 2xl:w-[450px] h-[45px] bg-primaryWhite focus:ring-lightGreen focus:border-lightGreen" placeholder="Search for projects">
            </div>
        </div>
    </div>
    <div class="flex flex-col w-auto gap-4 space-y-4 md:space-y-0">
        <div class="flex flex-col w-auto border-[1px] rounded-md p-2 gap-2 justify-start items-start">
            <div class="flex flex-row items-center gap-2">
                <img src="../../../../assets/icons/FilterIcon.png" class="size-[15px]"/>
                <p class="text-primaryBlack text-[14px]">Filters</p>
            </div>
            <div class="flex flex-row gap-4 items-start">
                <p class="text-primaryBlack font-light text-[12px]">Project status</p>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterActive" (change)="filterProjects()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">ACTIVE</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterFinished" (change)="filterProjects()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">FINISHED</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterPaused" (change)="filterProjects()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">PAUSED</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterScheduled" (change)="filterProjects()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">SCHEDULED</p>
                </div>
            </div>
        </div>
        <div class="flex flex-row gap-10 items-center">
            <span class="flex items-center text-sm xs:text-xs md:text-md lg:text-lg xl:texl-xl text-primaryBlack"><span class="flex size-2.5 sm:size-2 md:size-[10px] lg:size-[15px] xl:size-[20px] bg-active rounded-full me-1.5 flex-shrink-0"></span>Active</span>
            <span class="flex items-center text-sm xs:text-xs md:text-md lg:text-lg xl:texl-xl text-primaryBlack"><span class="flex size-2.5 sm:size-2 md:size-[10px] lg:size-[15px] xl:size-[20px] bg-scheduled rounded-full me-1.5 flex-shrink-0"></span>Scheduled</span>
            <span class="flex items-center text-sm xs:text-xs md:text-md lg:text-lg xl:texl-xl text-primaryBlack"><span class="flex size-2.5 sm:size-2 md:size-[10px] lg:size-[15px] xl:size-[20px] bg-paused rounded-full me-1.5 flex-shrink-0"></span>Paused</span>
            <span class="flex items-center text-sm xs:text-xs md:text-md lg:text-lg xl:texl-xl text-primaryBlack"><span class="flex size-2.5 sm:size-2 md:size-[10px] lg:size-[15px] xl:size-[20px] bg-finished rounded-full me-1.5 flex-shrink-0"></span>Finished</span>
        </div>
    </div>

    <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-5 4xl:grid-cols-6 gap-4">
        <ng-container *ngIf="!isLoading; else skeletons">
            <ng-container *ngIf="projects.length > 0; else noProjects">
                <button class="flex flex-col items-start rounded-md font-poppins gap-2 border-[1px] border-primaryBlack/50 group hover:bg-primaryBlack/30 
                            items-center justify-center transition-[0.5s] cursor-pointer"
                        (click)="openCreateProjectModal()">
                    <img src="../../../../assets/icons/AddProjectIcon.png" class="size-[70px] 2xl:size-[100px] opacity-[50%] group-hover:opacity-[100%]">
                    <p class="text-[36px] font-semibold text-primaryBlack opacity-[50%] group-hover:opacity-[100%]">Add new</p>
                </button>
                <app-detailed-project-card *ngFor="let project of filteredProjects; trackBy: trackByProjectId" (click)="viewProject(project.ID)"
                    [name]="project.Name"
                    [date]="project.StartDate"
                    [positionsCount]="project.PositionsCount"
                    [budget]="project.Budget"
                    [status]="project.ProjectStatus"
                />
            </ng-container>
        </ng-container>
        <ng-template #skeletons>
            <app-skeleton-project-card *ngFor="let skeleton of projectsCount"/>
        </ng-template>
        <ng-template #noProjects>
            <div class="flex w-full col-span-full">
                <p class="text-primaryBlack font-semibold text-[24px]">
                    There are no projects to display. <br>
                    <span class="font-light text-[16px]">You can create your new project by pressing the <span class="font-medium text-lightGreen">"Add new project"</span> button.</span>
                </p>
            </div>
            <button class="flex flex-col items-start rounded-md font-poppins gap-2 border-[1px] border-primaryBlack/50 group hover:bg-primaryBlack/30 
                        items-center justify-center transition-[0.5s] cursor-pointer p-8"
                    (click)="openCreateProjectModal()">
                <img src="../../../../assets/icons/AddProjectIcon.png" class="size-[70px] 2xl:size-[100px] opacity-[50%] group-hover:opacity-[100%]">
                <p class="text-[36px] font-semibold text-primaryBlack opacity-[50%] group-hover:opacity-[100%]">Add new</p>
            </button>
        </ng-template>
    </div>
</div>