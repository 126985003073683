import { Component, Input, OnInit } from '@angular/core';
import { Status } from '../../models/project-status';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-project-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './project-card.component.html',
  styleUrl: './project-card.component.css'
})

export class ProjectCardComponent implements OnInit{
  
  @Input() name : string = '';
  @Input() status : Status = Status.Active;
  @Input() date : string = '';
  @Input() width : String = "240px";

  public parsedDate : string = '';
  public icon : String = '';

  active : boolean = true;
  paused : boolean = false;
  scheduled : boolean = false;
  finished : boolean = false

  ngOnInit(): void {
    const date = new Date(this.date);
    this.parsedDate = date.toISOString().substring(0, 10);
    if(this.status === Status.Active){
      this.active = true;
      this.paused = false;
      this.scheduled = false;
      this.finished = false
    } else if(this.status === Status.Finished){
      this.active = false;
      this.paused = false;
      this.scheduled = false;
      this.finished = true
    } else if(this.status === Status.Paused){
      this.active = false;
      this.paused = true;
      this.scheduled = false;
      this.finished = false
    } else if(this.status === Status.Scheduled){
      this.active = false;
      this.paused = false;
      this.scheduled = true;
      this.finished = false
    }
  }

}
