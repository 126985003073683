import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../button/button.component';
import { FormsModule } from '@angular/forms';
import { EmployeeService } from '../../../services/employee.service';
import { Employee } from '../../../models/employee';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyService } from '../../../services/company.service';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-add-approver-modal',
  standalone: true,
  imports: [ButtonComponent, FormsModule],
  templateUrl: './add-approver-modal.component.html',
  styleUrl: './add-approver-modal.component.css'
})
export class AddApproverModalComponent implements OnInit{

  @Input() existingApprovers : any[] = [];
  
  @Output() approverAdded = new EventEmitter<void>();

  public isOpen : boolean = false; 
  public selectedItem : string = '';
  public filteredData : any[] = [];
  public extractedData : any[] = []
  public searchQuery : string = "";
  public data : Employee[] = [];
  public existingEmployee : boolean = false;
  public email : string = '';

  public selectedApprovers : string[] = [];

  constructor(public modalService : NgbActiveModal,
    public cdr: ChangeDetectorRef,
    private employeeService : EmployeeService,
    private companyService : CompanyService,
    private toast : NgToastService
  ){}

  ngOnInit(): void {
    this.getEmployees();
  }

  public getEmployees() {
    const companyId = sessionStorage.getItem('company');
    if(companyId) {
      this.employeeService.getAll().subscribe(
        (response : Employee[]) => {
          response.map(res => 
            {
              if(!this.existingApprovers.includes(res.Email)){
                this.data.push(res);
              }
            }
          )
          this.extractEmployeeData();
        },
        (error : HttpErrorResponse) => {
          console.log("Error while fetching employees, ", error.message);
        }
      );
    }
  }

  public extractEmployeeData() {
    let extractedData: { name: string; email: string; };

    this.data.forEach(employee => {
      extractedData = {
        name : employee.FirstName + " " + employee.LastName,
        email : employee.Email
      }

      this.extractedData.push(extractedData);
    })
    this.filteredData = this.extractedData;
  }

  public saveApprover() {
    var body;
    if(this.existingEmployee) {
      body = {
        mail : this.selectedApprovers
      }
    } else {
      const emailArray : string[] = [];
      emailArray.push(this.email);
      body = {
        mail : emailArray
      }
    }
    // console.log("EMAILS: ", body)
    this.companyService.addApprovers(body).subscribe(
      (response : any) => {
        console.log("Approvers added successfully!", response);
        this.approverAdded.emit();
        this.modalService.close();
      },
      (error : HttpErrorResponse) => {
        console.log("Error while adding new approvers, ", error.message);
        this.toast.danger("Error while adding new approvers!", "Error", 3000);
      }
    )
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }
  
  public onSelection(item: any, event: Event) {
    const target = event.target as HTMLInputElement
    if (target) {
      const isChecked = target.checked;
      if (isChecked) {
        if (!this.selectedApprovers.includes(item.email)) {
          this.selectedApprovers.push(item.email);
        }
      } else {
        this.selectedApprovers = this.selectedApprovers.filter(email => email !== item.email);
      }
      this.updateSelectedItem();
    }
  }
  
  public updateSelectedItem() {
    if (this.selectedApprovers.length === 1) {
      this.selectedItem = this.selectedApprovers[0];
    } else if (this.selectedApprovers.length > 1) {
      this.selectedItem = `Selected ${this.selectedApprovers.length}`;
    }
    console.log(this.selectedApprovers);
  }
  
  public onSearchChange() {
    if (!this.searchQuery) {
      this.filteredData = this.extractedData.map(item => ({
        ...item,
        checked: this.selectedApprovers.includes(item.email)
      }));
    } else {
      this.filteredData = this.extractedData.filter(employee =>
        employee.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      ).map(item => ({
        ...item,
        checked: this.selectedApprovers.includes(item.email)
      }));
    }
  }

  public addFromEmployees() : void {
    this.existingEmployee = !this.existingEmployee;
    console.log(this.existingEmployee)
  }
}
