import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/adminPages/dashboard/dashboard.component';
import { SignInComponent } from './pages/adminPages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/adminPages/sign-up/sign-up.component';
import { ViewUserProfileComponent } from './pages/adminPages/view-user-profile/view-user-profile.component';
import { EmployeesComponent } from './pages/adminPages/employees/employees.component';
import { CreateCompanyModalComponent } from './components/modals/create-company-modal/create-company-modal.component';
import { AllProjectsComponent } from './pages/adminPages/all-projects/all-projects.component';
import { CompanySettingsComponent } from './pages/adminPages/company-settings/company-settings.component';
import { ProjectDisplayComponent } from './pages/adminPages/project-display/project-display.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AllAssetsComponent } from './pages/adminPages/all-assets/all-assets.component';
import { ChooseCompanyComponent } from './pages/adminPages/choose-company/choose-company.component';
import { EmployeesLandingComponent } from './pages/employees-landing/employees-landing.component';
import { MagicLinkLoginComponent } from './pages/employeePages/magic-link-login/magic-link-login.component';
import { ProfileComponent } from './pages/adminPages/profile/profile.component';
import { NotificationsComponent } from './pages/employeePages/notifications/notifications.component';
import { VacanciesComponent } from './pages/employeePages/vacancies/vacancies.component';
import { EmployeeAssetsComponent } from './pages/employeePages/employee-assets/employee-assets.component';
import { FinanceComponent } from './pages/employeePages/finance/finance.component';
import { EngagementsComponent } from './pages/employeePages/engagements/engagements.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { authGuard } from './services/guards/auth.guard';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { roleGuard } from './services/guards/role.guard';
import { ApprovedPageComponent } from './pages/requestsPages/approved-page/approved-page.component';
import { AnalyticsComponent } from './pages/adminPages/analytics/analytics.component';

export const routes: Routes = [
    //Administrator routes
    {path: "globalLanding", component: LandingComponent},
    {path: "dashboard", component: DashboardComponent, canActivate:[authGuard, roleGuard]},
    {path: ":domain/landing/:token", component: EmployeesLandingComponent},
    {path: "signIn", component: SignInComponent},
    {path: "signUp", component: SignUpComponent},
    {path: "viewUser/:id", component: ViewUserProfileComponent, canActivate:[authGuard, roleGuard]},
    {path: "employees", component: EmployeesComponent, canActivate:[authGuard, roleGuard]},
    {path: "addCompany", component: CreateCompanyModalComponent, canActivate:[authGuard, roleGuard]},
    {path: "projects", component: AllProjectsComponent, canActivate:[authGuard, roleGuard]},
    {path: "settings", component: CompanySettingsComponent, canActivate:[authGuard, roleGuard]},
    {path: "project/:id", component: ProjectDisplayComponent},
    {path: "inventory", component: AllAssetsComponent},
    {path: "choose", component: ChooseCompanyComponent, canActivate:[authGuard, roleGuard]},
    {path: "analytics", component: AnalyticsComponent, canActivate:[authGuard, roleGuard]},
    {path: "myProfile", component: ProfileComponent, canActivate:[authGuard, roleGuard]},
    //annualLeavRequestApproved i annualLeaveRequestDenied

    //Requests pages
    {path: "annualLeaveRequestApproved", component:ApprovedPageComponent},
    {path: "annualLeaveRequestDenied", component:ApprovedPageComponent},

    {path: "pageNotFound", component: PageNotFoundComponent},
    {path: "unauthorized", component: UnauthorizedComponent},
    
    {path: ":domain/:token", redirectTo: ":domain/notifications/:token", pathMatch: 'prefix'},
    {path: "", redirectTo: "signIn", pathMatch: 'prefix'},
    {path: "**", redirectTo: "pageNotFound", pathMatch:"full"}
];

// @NgModule({
//     imports:[RouterModule.forRoot(routes, { useHash: true })],
//     exports:[RouterModule]
// })
// export class AppRoutingModule{}