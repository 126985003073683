<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto p-2 gap-4">
    <div class="flex flex-row w-full justify-between items-center">
        <div class="flex flex-row gap-4 items-center">
            <p class="text-[24px] font-semibold text-primaryBlack">Finance</p> 
        </div>
    </div>
    <div class="flex flex-row w-full items-center gap-4 justify-start">
        <app-incomes-section class="w-[50%] h-full"
            [incomes]="incomes"
        />
        <app-expenses-section class="w-[50%] h-full"
            [expenses]="expenses"
        />
    </div>
</div>