import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from '../../../components/button/button.component';
import { AssetsService } from '../../../services/assets.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateAssetComponent } from '../../../components/modals/create-asset/create-asset.component';
import { NgToastService } from 'ng-angular-popup';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WarningModalComponent } from '../../../components/modals/warning-modal/warning-modal.component';
import { EditAssetModalComponent } from '../../../components/modals/edit-asset-modal/edit-asset-modal.component';
import { AssetHistoryModalComponent } from '../../../components/modals/asset-history-modal/asset-history-modal.component';
import { AssetStatus } from '../../../models/assetStatus';
import { AssetOccupiedWarningModalComponent } from '../../../components/modals/asset-occupied-warning-modal/asset-occupied-warning-modal.component';
import { AuthService } from '../../../services/auth.service';
import { DeleteMoreAssetsModalComponent } from '../../../components/modals/delete-more-assets-modal/delete-more-assets-modal.component';

@Component({
  selector: 'app-all-assets',
  standalone: true,
  imports: [ButtonComponent, FormsModule],
  templateUrl: './all-assets.component.html',
  styleUrl: './all-assets.component.css'
})
export class AllAssetsComponent implements OnInit{

  public isLoading : boolean = true;
  public assetsCount = new Array(5);

  public approvalRequiredYes : boolean = false;
  public approvalRequiredNo : boolean = false;
  public assetPictureLink : any;

  public assets : any[] = [];
  public filteredAssets: any[] = [];
  public searchText: string = '';
  public assetStatus? : AssetStatus

  constructor(private assetsService : AssetsService,
    private modalService : NgbModal,
    private toast : NgToastService,
    private sanitizer: DomSanitizer,
    private authService : AuthService
  ){
    // this.authService.$refreshTokenReceived.subscribe(
    //   (response : any) => {
    //     this.fetchAssets();
    //   }
    // )
  }

  ngOnInit(): void {
    this.fetchAssets();
  }

  public fetchAssets(): void {
    this.isLoading = true;
    this.assetsService.getAll().subscribe(
      (response: any[]) => {
        this.assets = response;

        if(this.assets !== null && this.assets.length > 0){
          this.assets.forEach(asset => {
            this.getAssetImage(asset);
            this.searchAssets();
          });
        }

        this.isLoading = false;
      },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        console.log("Error while fetching assets, ", error.message);
      }
    );
  }

  public openAddAssetModal() {
    const modalRef = this.modalService.open(
      CreateAssetComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.assetCreated.subscribe(
      (res : any) => {
        this.toast.success("Asset created successfully!", "Success!", 3000);
        this.fetchAssets();
      }
    )
  }


  public getAssetImage(asset: any): void {
    const company = sessionStorage.getItem("company");
    let param;
    let companyId;

    if (asset.DefaultPicture) {
      param = "defaultasset";
      companyId = "firmegeneralepictures";
    } else {
      param = asset.ID;
      companyId = company;
    }

    console.log(param);
    console.log(companyId);

    this.assetsService.getAssetImage(param, companyId).subscribe(
      (response: string) => {
        asset.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
      },
      (error: HttpErrorResponse) => {
        console.log("Error while getting S3 image link, ", error.message);
      }
    );
  }


  public searchAssets(): void {
    if (!this.searchText) {
      this.filteredAssets = this.assets;
      console.log(this.filteredAssets)
    } else {
      this.filteredAssets = this.assets.filter(asset =>
        asset.Name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
  }

  public filterAssets() : void {
    if(this.approvalRequiredYes || this.approvalRequiredNo) {
      this.filteredAssets = this.assets.filter(asset => 
        (this.approvalRequiredYes && asset.Permission === true) || (this.approvalRequiredNo && asset.Permission === false)
      );
    } else {
      this.filteredAssets = this.assets
    }
  }

  // public startDeleteProcess(assetId : string) {
  //   this.assetsService.checkAssetStatus(assetId).subscribe(
  //     (response : AssetStatus) => {
  //       this.assetStatus = response;
  //       if(this.assetStatus.Occupied){
  //         const modalRef = this.modalService.open(
  //           AssetOccupiedWarningModalComponent,
  //           { backdrop : 'static', keyboard : true }
  //         );
  //         modalRef.componentInstance.assetStatusMessage = this.assetStatus.MessageResponse;

  //         modalRef.componentInstance.confirmation.subscribe(
  //           () => {
  //             this.deleteAsset(assetId);
  //           }
  //         )
  //       } else {
  //         const modalRef = this.modalService.open(
  //           WarningModalComponent,
  //           { backdrop : 'static', keyboard : true }
  //         );

  //         modalRef.componentInstance.confirmation.subscribe(
  //           () => {
  //             this.deleteAsset(assetId);
  //           }
  //         )
  //       }
  //     }
  //   )
  // }

  public deleteAsset(asset : any) {
    if(asset.Counter === 1){
      const modalRef = this.modalService.open(
        WarningModalComponent,
        { backdrop : 'static', keyboard : true }
      );
  
      modalRef.componentInstance.confirmation.subscribe(
        () => {
          this.assetsService.deleteAsset(asset.ID).subscribe(
            (response : any) => {
              console.log("Asset successfully deleted, ", response);
              this.fetchAssets();
              this.toast.success("Asset deleted successfully!", "Success!", 3000);
            },
            (error : HttpErrorResponse) => {
              console.log("Error while deleting asset, ", error.message);
              this.toast.danger("Error while deleting asset!", "Error!", 3000);
            }
          )
        }
      )
    } else {
      const modalRef = this.modalService.open(
        DeleteMoreAssetsModalComponent,
        { backdrop : 'static' , keyboard : true }
      )

      modalRef.componentInstance.assetId = asset.ID; 
      modalRef.componentInstance.assetsDeleted.subscribe(
        () => {
          this.toast.success("Assets successfully deleted!", "Success!", 3000);
          this.fetchAssets();
        }
      )
    }
  }

  public openEditAssetModal(assetId : string, assetName : string, serialNumber : string, imageUrl : string, requireApproval : boolean) {
    const modalRef = this.modalService.open(
      EditAssetModalComponent, 
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.assetId = assetId;
    modalRef.componentInstance.assetName = assetName;
    modalRef.componentInstance.serialNumber = serialNumber
    modalRef.componentInstance.imageUrl = imageUrl;
    modalRef.componentInstance.requireApproval = requireApproval

    modalRef.componentInstance.assetUpdated.subscribe(
      (res : any) => {
        this.toast.success("Asset successfully updated!", "Success!", 3000)
        this.fetchAssets();
      }
    )
  }

  public viewAssetHistory(asset : any, defaultPicture : boolean) {
    const modalRef = this.modalService.open(
      AssetHistoryModalComponent,
      { backdrop : 'static', keyboard : true }
    )

    modalRef.componentInstance.asset = asset
    modalRef.componentInstance.defaultPicture = defaultPicture;
  }
}
