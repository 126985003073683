<div class="flex flex-col w-full items-center justify-start rounded-full">
    <table class="w-full text-left text-primaryBlack font-poppins">
        <thead class="text-primaryBlack uppercase text-[16px] bg-lightGreen">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Name
                </th>
                <th scope="col" class="px-6 py-3 w-full">
                    Skills
                </th>
                <th scope="col" class="px-6 py-3">
                    Action
                </th>
            </tr>
        </thead>
        <tbody class="text-[12px]">
            <tr class="bg-white border-b border-primaryBlack/40">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <div class="flex size-10 rounded-full bg-lightGreen">
                    </div>
                    <div class="ps-3">
                        <div class="text-primaryBlack font-semibold">Nemanja Todorovic</div>
                        <div class="font-normal text-primaryBlack">Nemkac</div>
                    </div>  
                </th>
                <td class="px-6 py-4 w-full flex-1">
                    <div class="flex w-full items-center justify-start flex-wrap">
                        <img src="../../../assets/icons/AngularIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/ReactIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/FlutterIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/HTML5Icon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/CSS3Icon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/JavaScriptIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/TypeScriptIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/JavaIcon.png" class="size-[20px] m-1"/>
                    </div>
                </td>
                <td class="px-6 py-4">
                    <p class="font-medium text-darkGreen hover:underline hover:text-lightGreen cursor-pointer">View user</p>
                </td>
            </tr>

            <tr class="bg-white border-b border-primaryBlack/40">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <div class="flex size-10 rounded-full bg-lightGreen">
                    </div>
                    <div class="ps-3">
                        <div class="text-primaryBlack font-semibold">Nikola Kojic</div>
                        <div class="font-normal text-primaryBlack">Kojic</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    <div class="flex w-full items-center justify-start flex-wrap">
                        <img src="../../../assets/icons/GolangIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PostgreSQLIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/NodeJSIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/JavaIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PythonIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/AngularIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/ReactIcon.png" class="size-[20px] m-1"/>
                    </div>
                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-medium text-darkGreen hover:underline hover:text-lightGreen">View user</a>
                </td>
            </tr>

            <tr class="bg-white border-b border-primaryBlack/40">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <div class="flex size-10 rounded-full bg-lightGreen">
                    </div>
                    <div class="ps-3">
                        <div class="text-primaryBlack font-semibold">Nemanja Ranitovic</div>
                        <div class="font-normal text-primaryBlack">Ranita</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    <div class="flex w-full items-center justify-start flex-wrap">
                        <img src="../../../assets/icons/GolangIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PostgreSQLIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/NodeJSIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/JavaIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PythonIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/AngularIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/ReactIcon.png" class="size-[20px] m-1"/>
                    </div>                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-medium text-darkGreen hover:underline hover:text-lightGreen">View user</a>
                </td>
            </tr>
            
            <tr class="bg-white border-b border-primaryBlack/40">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <div class="flex size-10 rounded-full bg-lightGreen">
                    </div>
                    <div class="ps-3">
                        <div class="text-primaryBlack font-semibold">Nemanja Ranitovic</div>
                        <div class="font-normal text-primaryBlack">Ranita</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    <div class="flex w-full items-center justify-start flex-wrap">
                        <img src="../../../assets/icons/GolangIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PostgreSQLIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/NodeJSIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/JavaIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PythonIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/AngularIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/ReactIcon.png" class="size-[20px] m-1"/>
                    </div>                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-medium text-darkGreen hover:underline hover:text-lightGreen">View user</a>
                </td>
            </tr>

            <tr class="bg-white border-b border-primaryBlack/40">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <div class="flex size-10 rounded-full bg-lightGreen">
                    </div>
                    <div class="ps-3">
                        <div class="text-primaryBlack font-semibold">Nemanja Ranitovic</div>
                        <div class="font-normal text-primaryBlack">Ranita</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    <div class="flex w-full items-center justify-start flex-wrap">
                        <img src="../../../assets/icons/GolangIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PostgreSQLIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/NodeJSIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/JavaIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PythonIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/AngularIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/ReactIcon.png" class="size-[20px] m-1"/>
                    </div>                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-medium text-darkGreen hover:underline hover:text-lightGreen">View user</a>
                </td>
            </tr>

            <tr class="bg-white border-b border-primaryBlack/40">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <div class="flex size-10 rounded-full bg-lightGreen">
                    </div>
                    <div class="ps-3">
                        <div class="text-primaryBlack font-semibold">Nemanja Ranitovic</div>
                        <div class="font-normal text-primaryBlack">Ranita</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    <div class="flex w-full items-center justify-start flex-wrap">
                        <img src="../../../assets/icons/GolangIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PostgreSQLIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/NodeJSIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/JavaIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PythonIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/AngularIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/ReactIcon.png" class="size-[20px] m-1"/>
                    </div>                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-medium text-darkGreen hover:underline hover:text-lightGreen">View user</a>
                </td>
            </tr>

            <tr class="bg-white border-b border-primaryBlack/40">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <div class="flex size-10 rounded-full bg-lightGreen">
                    </div>
                    <div class="ps-3">
                        <div class="text-primaryBlack font-semibold">Nemanja Ranitovic</div>
                        <div class="font-normal text-primaryBlack">Ranita</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    <div class="flex w-full items-center justify-start flex-wrap">
                        <img src="../../../assets/icons/GolangIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PostgreSQLIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/NodeJSIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/JavaIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PythonIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/AngularIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/ReactIcon.png" class="size-[20px] m-1"/>
                    </div>                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-medium text-darkGreen hover:underline hover:text-lightGreen">View user</a>
                </td>
            </tr>

            <tr class="bg-white border-b border-primaryBlack/40">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <div class="flex size-10 rounded-full bg-lightGreen">
                    </div>
                    <div class="ps-3">
                        <div class="text-primaryBlack font-semibold">Nemanja Ranitovic</div>
                        <div class="font-normal text-primaryBlack">Ranita</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    <div class="flex w-full items-center justify-start flex-wrap">
                        <img src="../../../assets/icons/GolangIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PostgreSQLIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/NodeJSIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/JavaIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PythonIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/AngularIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/ReactIcon.png" class="size-[20px] m-1"/>
                    </div>                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-medium text-darkGreen hover:underline hover:text-lightGreen">View user</a>
                </td>
            </tr>

            <tr class="bg-white border-b border-primaryBlack/40">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <div class="flex size-10 rounded-full bg-lightGreen">
                    </div>
                    <div class="ps-3">
                        <div class="text-primaryBlack font-semibold">Nemanja Ranitovic</div>
                        <div class="font-normal text-primaryBlack">Ranita</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    <div class="flex w-full items-center justify-start flex-wrap">
                        <img src="../../../assets/icons/GolangIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PostgreSQLIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/NodeJSIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/JavaIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/PythonIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/AngularIcon.png" class="size-[20px] m-1"/>
                        <img src="../../../assets/icons/ReactIcon.png" class="size-[20px] m-1"/>
                    </div>                </td>
                <td class="px-6 py-4">
                    <a href="#" class="font-medium text-darkGreen hover:underline hover:text-lightGreen">View user</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>