import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddTechstackExperienceModalComponent } from '../../../components/modals/add-techstack-experience-modal/add-techstack-experience-modal.component';
import { CommonModule } from '@angular/common';
import { NgToastService } from 'ng-angular-popup';
import { WarningModalComponent } from '../../../components/modals/warning-modal/warning-modal.component';
import { AddApproverModalComponent } from '../../../components/modals/add-approver-modal/add-approver-modal.component';
import { NavigationService } from '../../../services/navigation.service';
import { AuthService } from '../../../services/auth.service';
import { EmployeeService } from '../../../services/employee.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EditCompanyModalComponent } from '../../../components/modals/edit-company-modal/edit-company-modal.component';

@Component({
  selector: 'app-company-settings',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './company-settings.component.html',
  styleUrl: './company-settings.component.css'
})
export class CompanySettingsComponent implements OnInit{

  public techStacks : any[] = [];
  public experienceLevels : any[] = []
  public professions : any[] = []
  public editingExperienceLevel : boolean = false;
  public editingTechStack : boolean = false;
  public company : any;
  public settingsCompleted : boolean = false;
  public displayDate : string = '';
  public companyLogoLink : any;

  public isLoading : boolean = true;
  public approversCount = new Array(5);

  public showGeneral : boolean = true;
  public showTechStack : boolean = false;
  public showProfessions : boolean = false;
  public showExperienceLevels : boolean = false;
  public showApprovers : boolean = false;
  public hasPicture : boolean = false;

  colors: string[] = ['#A09EBB', '#39b36a', '#966B9D',  '#FBC531', '#273C75', '#F1454D'];

  constructor(private companyService : CompanyService,
    private modalService : NgbModal,
    private toast : NgToastService,
    private authService : AuthService,
    private navigationService : NavigationService,
    private employeeService : EmployeeService,
    private sanitizer : DomSanitizer
  ){}

  ngOnInit(): void {
    const settings = sessionStorage.getItem('settings');
    if(settings){
      this.settingsCompleted = false;
      this.companyService.companyChangesRequiredSubject.next(true);
    } else {
      this.settingsCompleted = true;
    }
    this.fetchCompany();
  }

  public fetchCompany() : void {
    this.isLoading = true;

    this.companyService.getCompany().subscribe(
      (response : any) => {
        this.company = response;
        this.techStacks = response.TechStack;
        this.experienceLevels = response.ExperienceLevels;
        this.professions = response.Professions;

        // if(this.company.TechStack.length === 0) {
        //   this.toggleTech();
        // }
        // if(this.company.ExperienceLevels.length === 0){
        //   this.toggleExperience();
        // }
        // if(this.company.Professions.length === 0){
        //   this.toggleProfessions();
        // }
        if(this.company.TechStack.length > 0 && this.company.ExperienceLevels.length > 0 && this.company.Professions.length > 0){
          this.settingsCompleted = true;
          sessionStorage.removeItem('settings');
        } else {
          this.settingsCompleted = false;
          sessionStorage.setItem('settings', "Not completed");
        }

        const date = new Date(this.company.Birthday);
        this.displayDate = date.toISOString().substring(0, 10);

        sessionStorage.setItem('techStack', JSON.stringify(this.techStacks));
        sessionStorage.setItem('professions', JSON.stringify(this.professions));
        sessionStorage.setItem('experienceLevels', JSON.stringify(this.experienceLevels));
        console.log(response);

        this.getCompanyLogo();
        
        this.isLoading = false;
      },
      (error : HttpErrorResponse) => {
        this.isLoading = false;
        console.log("Error while fetching company, ", error.message);
      }
    )
  }

  public getCompanyLogo() {
    this.companyService.getCompanyLogo().subscribe(
      (response : string) => {
        // this.employeePictureLink = response;
        this.companyLogoLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
        this.hasPicture = true;
      },
      (error : HttpErrorResponse) => {
        this.hasPicture = false;
        console.log("Error while getting s3 image link, ", error.message);
      }
    )
  }

  public deleteApprover(approverEmail : string) : void {
    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.companyService.deleteApprover(approverEmail).subscribe(
          (response : any) => {
            console.log(response);
            this.toast.warning("Approver successfully deleted!", "Success!", 3000);
            this.fetchCompany();
          },
          (error : HttpErrorResponse) => {
            console.log(error.message);
          }
        )
      }
    )
  }

  getBackgroundColor(index: number): string {
    const color = this.colors[index % this.colors.length];
    const opacity = '36';
    return color + opacity;
  }

  getColor(index: number): string {
    return this.colors[index % this.colors.length];
  }

  openAddTechStackModal() {
    this.goToAddTechStack();
  }

  openAddProfessionModal() {
    this.goToAddProfession();
  }

  public goToAddProfession(): void {
    const modalRef = this.modalService.open(
      AddTechstackExperienceModalComponent,
      { backdrop: 'static', keyboard: true }
    );

    modalRef.componentInstance.text = 'Add profession';
    modalRef.componentInstance.placeholder = 'Profession name';
    modalRef.componentInstance.type = 'Professions';

    
    modalRef.componentInstance.companySettingsChanged.subscribe(() => {
      this.fetchCompany();
      this.toast.success("Profession successfully added!", "Success!", 3000)
    });
  }

  public goToAddTechStack(): void {
    const modalRef = this.modalService.open(
      AddTechstackExperienceModalComponent,
      { backdrop: 'static', keyboard: true }
    );

    modalRef.componentInstance.text = 'Add tech stack';
    modalRef.componentInstance.placeholder = 'Tech stack name';
    modalRef.componentInstance.type = 'TechStack';

    
    modalRef.componentInstance.companySettingsChanged.subscribe(() => {
      this.fetchCompany();
      this.toast.success("Tech successfully added!", "Success!", 3000)
    });
  }

  public openAddExperienceLevelModal() {
    if(this.experienceLevels.length < 6) {
      this.goToAddExperienceLevel();
    } else {
      this.toast.info("You have already created maximum number of levels", "Info!", 3000);
    }
  }

  public goToAddExperienceLevel(): void {
    const modalRef = this.modalService.open(
      AddTechstackExperienceModalComponent,
      { backdrop: 'static', keyboard: true }
    );

    modalRef.componentInstance.text = 'Add experience level';
    modalRef.componentInstance.placeholder = 'Experience level name';
    modalRef.componentInstance.type = 'ExperienceLevels';

    modalRef.componentInstance.companySettingsChanged.subscribe(() => {
      this.fetchCompany();
      this.toast.success("Experience level successfully added!", "Success!", 3000)
    });
  }

  public enableEditingExperienceLevel() : void {
    this.editingExperienceLevel = !this.editingExperienceLevel;
  }

  public enableEditingTechStack() : void {
    this.editingTechStack = !this.editingTechStack;
  }

  public deleteStack(stackType : string, stackId : string, event : Event) : void {
    event.stopPropagation();
    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop: 'static', keyboard: true }
    );
    modalRef.componentInstance.confirmation.subscribe(
      (response : any) => {
        this.companyService.deleteStack(stackType, stackId).subscribe(
          (response : any) => {
            this.fetchCompany();
            this.toast.success(stackType + ' successfully deleted!', "Success!", 3000);
          },
          (error : HttpErrorResponse) => {
            this.toast.danger("Error while deleting " + stackType, "Error!", 3000);
          }
        );
      }
    )
  }

  public addApprover() {
    const modalRef = this.modalService.open(
      AddApproverModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.existingApprovers = this.company.VacationApprovers;
    modalRef.componentInstance.approverAdded.subscribe(
      () => {
        this.toast.success("Approvers successfully added!", "Success!", 3000);
        this.fetchCompany();
      }
    )
  }

  public deleteCompany() {
    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.companyService.deleteCompany().subscribe(
          (response : any) => {
            console.log(response);
            sessionStorage.removeItem('company');
            sessionStorage.removeItem('CompanyName');
            sessionStorage.removeItem('experienceLevels');
            sessionStorage.removeItem('professions');
            sessionStorage.removeItem('techStack');
            sessionStorage.removeItem('settings');
            this.companyService.companyStatusSubject.next(false);
            this.navigationService.navigateToChooseCompany();
            window.location.reload();
          },
          (error : HttpErrorResponse) => {
            console.log("Error while deleting company," , error.message);
          }
        );
      }
    )
  }


  public toggleEdit(): void {
    const modalRef = this.modalService.open(
      EditCompanyModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.company = this.company;
  }

  public toggleGeneral(){
    this.showGeneral = true;
    this.showApprovers = false;
    this.showExperienceLevels  = false;
    this.showProfessions = false;
    this.showTechStack = false;
  }

  public toggleApprovers(){
    this.showGeneral = false;
    this.showApprovers = true;
    this.showExperienceLevels  = false;
    this.showProfessions = false;
    this.showTechStack = false;
  }

  public toggleExperience(){
    this.showGeneral = false;
    this.showApprovers = false;
    this.showExperienceLevels  = true;
    this.showProfessions = false;
    this.showTechStack = false;
  }

  public toggleProfessions(){
    this.showGeneral = false;
    this.showApprovers = false;
    this.showExperienceLevels  = false;
    this.showProfessions = true;
    this.showTechStack = false;
  }

  public toggleTech(){
    this.showGeneral = false;
    this.showApprovers = false;
    this.showExperienceLevels  = false;
    this.showProfessions = false;
    this.showTechStack = true;
  }


}
