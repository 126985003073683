import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dropdown-menu',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './dropdown-menu.component.html',
  styleUrl: './dropdown-menu.component.css'
})
export class DropdownMenuComponent implements OnInit{
  @Input() width : string = "230px";
  @Input() inputPlaceHolder : string = "";
  @Input() data : string[] = []

  @Output() selectionChanged: EventEmitter<string> = new EventEmitter<string>();

  isOpen : boolean = false; 
  selectedItem: string = '';
  filteredData: string[] = [];
  searchQuery: string = "";

  public menuWidth : string = "230px"

  constructor(public cdr: ChangeDetectorRef) {}


  ngOnInit() {
    this.filteredData = this.data;
  }
  
  ngOnChanges() {
    this.filteredData = this.data;
    this.cdr.detectChanges();
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    if(this.width == '100%'){
      this.menuWidth = "350px"
    } else {
      this.menuWidth = this.width;
    }
  }

  public onSelection(item: string) {
    this.selectedItem = item;
    this.selectionChanged.emit(item);
    this.toggleDropdown();
  }

  // public onSearchChange(searchValue: string) {
  //   if(this.searchQuery){
  //     this.filteredData = this.data.filter(tmp => 
  //       tmp.toLowerCase().includes(this.searchQuery.toLowerCase())
  //     );
  //   } else {
  //     this.filteredData = this.data
  //   }
  // }

  onSearchChange(searchValue: string) {
    this.searchQuery = searchValue;
    this.filteredData = this.searchQuery ? 
      this.data.filter(item => item.toLowerCase().startsWith(this.searchQuery.toLowerCase())) 
      : this.data;
  }
}
