import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from "../../button/button.component";
import { ProjectService } from '../../../services/project.service';
import { FormsModule } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-position-modal',
  standalone: true,
  imports: [CommonModule, ButtonComponent, FormsModule],
  templateUrl: './create-position-modal.component.html',
  styleUrl: './create-position-modal.component.css'
})
export class CreatePositionModalComponent implements OnInit{

  @Input() company: any;
  @Input() existingProject : boolean = false;
  @Input() projectName : string = '';
  @Input() projectId : string = '';

  @Input() editingExistingPosition : boolean = true;
  @Input() editingPositionId : string = '';
  @Input() editingCurrentTechStackId : string = '';
  @Input() editingCurrentExperienceLevelId : string = '';
  @Input() editingCurrentProfessionId : string = '';
  @Input() editingCurrentCost : number = 0;


  @Output() positonAdded = new EventEmitter<void>();
  @Output() positionEdited = new EventEmitter<string[]>();

  professions: any[] = [];
  techStack : any[] = [];
  experienceLevels : any[] = [];

  activeProfessionId: string = ''; 
  activeTechStackId : string = '';
  activeExperienceLevelId : string = '';

  selectedProfession : any;
  selectedTechStack : any;
  selectedExperienceLevel : any;
  cost : number = 0;

  constructor(private modalService: NgbActiveModal,
    private projectService : ProjectService
  ) {}

  ngOnInit(): void {
    const proff = sessionStorage.getItem('professions');
    const tech = sessionStorage.getItem('techStack');
    const exp = sessionStorage.getItem('experienceLevels');

    if(proff && tech && exp) {
      this.professions = JSON.parse(proff);
      this.techStack = JSON.parse(tech);
      this.experienceLevels = JSON.parse(exp);
    }
    if(this.editingExistingPosition) {
      this.activeProfessionId = this.editingCurrentProfessionId;
      this.activeTechStackId = this.editingCurrentTechStackId;
      this.activeExperienceLevelId = this.editingCurrentExperienceLevelId;
      this.cost = this.editingCurrentCost;
    }
  }

  public closeModal(): void {
    this.modalService.close();
  }

  public setActive(profession: any): void {
    console.log("Profession: ", profession);
    this.activeProfessionId = profession.ID;
    this.selectedProfession = profession;
  }

  public isActive(professionId: string): boolean {
    return this.activeProfessionId === professionId;
  }

  public setActiveTechStack(tech: any): void {
    console.log("Tech: ", tech);
    this.activeTechStackId = tech.ID;
    this.selectedTechStack = tech;
  }

  public isActiveTechStack(techId: string): boolean {
    return this.activeTechStackId === techId;
  }

  public setActiveLevel(level: any): void {
    console.log("Experience level: ", level);
    this.activeExperienceLevelId = level.ID;
    this.selectedExperienceLevel = level;
  }

  public isActiveLevel(levelId: string): boolean {
    return this.activeExperienceLevelId === levelId;
  }

  public savePosition() : void {
    if(this.existingProject == false){
      const position = {
        PositionID : this.selectedProfession.ID,
        PositionName : this.selectedProfession.Name,
        PrimarySkillID : this.selectedTechStack.ID,
        PrimarySkillName : this.selectedTechStack.Name,
        SkillLevelID : this.selectedExperienceLevel.ID,
        SkillLevelName : this.selectedExperienceLevel.Name,
        Cost : this.cost
      }
      console.log('Created position: ', position);
      this.projectService.addPosition(position);
      this.modalService.close();
    } else {
      const body = {
        projectName : this.projectName,
        primarySkill : this.selectedTechStack.ID,
        skillLevel : this.selectedExperienceLevel.ID,
        profession : this.selectedProfession.ID,
        cost : this.cost
      }
      this.projectService.createPosition(body).subscribe(
        (response : any) => {
          console.log(response);
          this.positonAdded.emit();
          this.closeModal();
        },
        (error : HttpErrorResponse) => {
          console.log("Error while adding position to existing project, ", error.message);
        }
      )

    }
  }

  public editPosition(){
    const queryParams = `primarySkill=${this.activeTechStackId}&profession=${this.activeProfessionId}&skillLevel=${this.activeExperienceLevelId}&cost=${this.cost}`;

    this.projectService.editPosition(queryParams, this.projectId, this.editingPositionId).subscribe(
      (response : any) => {
        console.log("Profession successfully edited, ",response);
        const array = [this.activeTechStackId, this.activeProfessionId, this.activeExperienceLevelId];
        this.positionEdited.emit(array);
        this.modalService.close();
      },
      (error : HttpErrorResponse) => {
        console.log("Error while editing position, ", error.message);
      }
    );
  }
}
