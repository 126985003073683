<div class="flex flex-col w-full h-full items-start font-poppins p-2 justify-between">
    <div class="flex flex-col w-full h-full items-start gap-4 overflow-y-auto">
        <div class="flex flex-row w-full justify-between items-center">
            @if (hasCompnay) {
                <p class="text-[24px] font-semibold text-primaryBlack">Choose company</p> 
            } @else {
                <p class="text-[24px] font-semibold text-primaryBlack">Create a company</p> 
            }
        </div>
        @if (hasCompnay) {
            <p class="text-[14px] font-light text-primaryBlack/50">
                Here is a list of companies you can manage. Select one to continue using the application.
            </p>        
        } @else {
            <p class="text-[14px] font-light text-primaryBlack/50">
                Press <span class="text-lightGreen font-medium">"Add new"</span> to create your very first company.
            </p>        
        }
        <!-- <p class="text-[14px] font-light text-primaryBlack/50">
            Here is a list of companies you can manage. Select one to continue using the application.
        </p> -->
        <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 gap-4 col-auto">
            <button class="flex flex-col items-start rounded-md py-2 px-24 font-poppins gap-2 border-[1px] border-primaryBlack/50 group hover:bg-primaryWhite 
                            items-center justify-center transition-[0.5s] cursor-pointer"
                    (click)="openCreateNewCompanyModal()">
                <img src="../../../../assets/icons/PlusIcon.png" class="opacity-[50%] group-hover:opacity-[100%]">
                <p class="text-[36px] font-medium text-primaryBlack opacity-[50%] group-hover:opacity-[100%]">Add new</p>
    
            </button>
            @for (company of companies; track $index) {
                <div [ngClass]="{'bg-lightGreen/30 border-[2px] border-lightGreen': isActive(company.id),
                                'bg-primaryWhite' : !isActive(company.id)}"
                    class="flex relative border-[1px] hover:translate-y-[-5px] transition-[0.5s] hover:border-lightGreen rounded-md
                            cursor-pointer hover:shadpw-md w-full"
                    (click)="setActive(company.id)">
                    <app-company-card class="w-full"
                        [company]="company"
                    />
                    <!-- @if (isActive(company.id)) {
                        <div class="flex items-center justify-center p-2 rounded-full bg-lightGreen absolute top-[-15px] right-[-15px]">
                            <img src="../../../assets/icons/SelectedIcon.png" class="nav_menu_icon size-[20px]"/>
                        </div>
                    } -->
                </div>
            }
        </div>
    </div>
    <!-- <div class="flex flex-row w-full justify-end">
        <button [ngClass]="{'bg-primaryBlack/20 text-primaryBlack/50': !companySelected, 'bg-lightGreen hover:bg-lightHoverGreen text-primaryWhite': companySelected}"
                class="flex h-[45px] px-8 items-center justify-center rounded-md bg-lightGreen hover:bg-lightHoverGreen transition-[0.5s]
                        font-medium text-[18px] text-primaryWhite"
                [disabled]="!companySelected" (click)="proceedWithSelectedCompany()">
            Proceed
        </button>
    </div> -->
</div>