<div class="flex flex-col lg:size-[250px] 4xl:size-[600px] 3xl:size-[400px] 2xl:size-[280px] md:size-[220px] sm:size-[200px] xs:size-[150px] 
            items-center justify-center group hover:translate-y-[-6px] md:text-[12px] lg:text-[12px] xl:text-[14px] 2xl:text-[14px] 3xl:text-[20px]
            rounded-md group cursor-pointer transition-[0.5s] gap-4 font-poppins hover:shadow-xl"
    [ngClass]="{'bg-active' : active,
    'bg-scheduled' : scheduled,
    'bg-paused' : paused,
    'bg-finished' : finished}">
    <div class="flex flex-col items-center justify-center w-full">
        <div class="text-center word-break-all w-full px-2">
            <p class="sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] 2xl:text-[24px] 3xl:text-[34px] 4xl:text-[44px] font-semibold text-balance">{{name}}</p>
        </div>
    </div>
    <div class="flex flex-col items-center justify-center">
        <div class="flex flex-row w-full items-center justify-center">
            <img src="../../../assets/icons/DateIcon.png" class="sm:size-[10px] md:size-[12px] lg:size-[12px] xl:size-[14px] 2xl:size-[16px] 3xl:size-[26px]"/>
            <p class="font-medium ml-2">{{dateToDisplay}}</p>
        </div>
        <div class="flex flex-row w-full items-center justify-center">
            <p class="font-light">Status: </p>
            <p class="font-semibold ml-2">{{status}}</p>
        </div>
        <div class="flex flex-row w-full items-center justify-center">
            <p class="font-light">No. of positions: </p>
            <p class="font-semibold ml-2"> {{positionsCount}}</p>
        </div>
    </div>
    <div class="view-profile hidden absolute top-0 left-0 w-full h-full items-center justify-center 
                bg-black bg-opacity-50 text-white text-[20px] font-medium">
        View Project
    </div>
</div>
