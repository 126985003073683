<div class="flex flex-col w-full h-full items-start font-poppins overflow-y-auto p-2 gap-4">
    <div class="flex flex-row w-full justify-between items-center">
        <div class="flex flex-row items-center gap-4">
            <p class="text-[24px] font-semibold text-primaryBlack">Employees</p>
            <div class="relative">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="text" [(ngModel)]="searchText" (input)="searchEmployees()" id="table-search-users" 
                        class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-80 2xl:w-[450px] h-[45px] focus:ring-lightGreen focus:border-lightGreen" 
                        placeholder="Search for employees">
            </div>
        </div>
    </div>

    <div class="flex justify-between items-end w-full flex-row md:flex-row space-y-4 md:space-y-0">
        <div class="flex flex-col border-[1px] rounded-md p-2 gap-2 justify-start items-start">
            <div class="flex flex-row items-center gap-2">
                <img src="../../../../assets/icons/FilterIcon.png" class="size-[15px]"/>
                <p class="text-primaryBlack text-[14px]">Filters</p>
            </div>
            <div class="flex flex-row gap-4 items-start">
                <p class="text-primaryBlack font-light text-[12px]">Employees role</p>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterEmployee" (change)="filterEmployees()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">EMPLOYEE</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterManagers" (change)="filterEmployees()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">MANAGER</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterOwners" (change)="filterEmployees()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">OWNER</p>
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input type="checkbox" class="w-4 h-4 text-lightGreen bg-gray-100 border-gray-300 rounded focus:ring-lightGreen"
                            [(ngModel)]="filterAdmin" (change)="filterEmployees()"/>
                    <p class="text-primaryBlack font-medium text-[12px]">ADMIN</p>
                </div>
            </div>
        </div>
    </div>

    <div class="grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-5 4xl:grid-cols-6 gap-4">
        <ng-container *ngIf="!isLoading; else skeletons">
            <ng-container *ngIf="employees.length > 0; else noEmployees">
                <button class="flex flex-col p-6 items-start rounded-md font-poppins gap-2 border-[1px] border-primaryBlack/50 group hover:bg-primaryBlack/30 
                            items-center justify-center transition-[0.5s] cursor-pointer"
                        (click)="openAddEmployeeModal()">
                    <img src="../../../../assets/icons/AddEmployeeIcon.png" class="size-[70px] 2xl:size-[100px] opacity-[50%] group-hover:opacity-[100%]">
                    <p class="text-[36px] font-semibold text-primaryBlack opacity-[50%] group-hover:opacity-[100%]">Add new</p>
                </button>
                <app-employee-card *ngFor="let employee of filteredEmployees; trackBy: trackByEmployeeId"
                    [firstname]="employee.FirstName"
                    [lastname]="employee.LastName"
                    [grossSalary]="employee.Financial.Gross"
                    [Skills]="employee.Skills"
                    [userId]="employee.EmployeeID"
                    [role]="employee.Role"
                    [requiresEditing]="employee.requiresEditing"
                    [defaultPicture]="employee.DefaultPicture"
                    [fullName]="employee.FullName">
                </app-employee-card>
            </ng-container>
        </ng-container>
        <ng-template #skeletons>
            <app-skeleton-employee-card *ngFor="let skeleton of employeesCount"/>
        </ng-template>
        <ng-template #noEmployees>
            <div class="flex w-full col-span-full">
                <p class="text-primaryBlack font-semibold text-[24px]">
                    There are no employees to display. <br>
                    <span class="font-light text-[16px]">You can create your new employee by pressing the <span class="font-medium text-lightGreen">"Add new employee"</span> button.</span>
                </p>
            </div>
            <button class="flex flex-col items-start rounded-md font-poppins gap-2 border-[1px] border-primaryBlack/50 group hover:bg-primaryBlack/30 
                            items-center justify-center transition-[0.5s] cursor-pointer p-8"
                    (click)="openAddEmployeeModal()">
                <img src="../../../../assets/icons/AddEmployeeIcon.png" class="size-[70px] 2xl:size-[100px] opacity-[50%] group-hover:opacity-[100%]">
                <p class="text-[36px] font-semibold text-primaryBlack opacity-[50%] group-hover:opacity-[100%]">Add new</p>
            </button>
        </ng-template>
    </div>
</div>