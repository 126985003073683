<div class="flex h-[45px] relative">
    <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
        <svg class="w-4 h-4 text-gray-500 dark:text-primaryWhite" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
        </svg>
    </div>
    <input type="text" [(ngModel)]="searchText" (input)="globalSearch()" id="table-search-users" class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack rounded-lg w-80 2xl:w-[450px] h-[45px] bg-[#C7C8CC] focus:ring-lightGreen focus:border-lightGreen" placeholder="Search anything...">
</div>
@if (globalDropdownVisible) {
    <div class="absolute z-[10] top-12 w-full max-h-[400px] bg-lightGrey p-2 rounded-md shadow-xl overflow-y-auto">
        <ul class="space-y-2 font-medium">
            <button type="button" class="flex items-center w-full p-2 text-base gap-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-primaryWhite" 
                    (click)="showMenu('Employees')">
                <img src="../../../assets/icons/EmployeesIcon.png" class="size-[20px]"/>
                <span class="flex-1 text-left rtl:text-right whitespace-nowrap">Employees</span>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg>
            </button>
            @if (employeesVisible) {
                <ul class="space-y-2">
                    @for (employee of employees; track $index) {
                        <li class="hover:bg-primaryWhite duration-75 pl-8 py-1.5 cursor-pointer rounded-md"
                            (click)="navigate('Employee', employee.ID)">
                            <div class="flex flex-col w-full">
                                <p class="text-[14px] font-merium">{{employee.Name}}</p>
                                <p class="text-[10px] font-light">Matched by: <span class="text-lightGreen">{{employee.Field}}</span></p>
                            </div>
                        </li>
                    }
                </ul>
            }
            <button type="button" class="flex items-center w-full p-2 text-base gap-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-primaryWhite" 
                    (click)="showMenu('Projects')">
                <img src="../../../assets/icons/ProjectsIcon.png" class="size-[20px]"/>
                <span class="flex-1 text-left rtl:text-right whitespace-nowrap">Projects</span>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg>
            </button>
            @if (projectsVisible) {
                <ul class="space-y-2">
                    @for (project of projects; track $index) {
                        <li class="hover:bg-primaryWhite duration-75 pl-8 py-1.5 cursor-pointer rounded-md"
                            (click)="navigate('Project', project.ID)">
                            <div class="flex flex-col w-full">
                                <p class="text-[14px] font-merium">{{project.Name}}</p>
                                <p class="text-[10px] font-light">Matched by: <span class="text-lightGreen">{{project.Field}}</span></p>
                            </div>
                        </li>
                    }
                </ul>
            }
            <button type="button" class="flex items-center w-full p-2 text-base gap-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-primaryWhite" 
                    (click)="showMenu('Assets')">
                <img src="../../../assets/icons/AssetsIcon.png" class="size-[20px]"/>
                <span class="flex-1 text-left rtl:text-right whitespace-nowrap">Assets</span>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                   <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                </svg>
            </button>
            @if (assetsVisible) {
                <ul class="space-y-2">
                    @for (asset of assets; track $index) {
                        <li class="hover:bg-primaryWhite duration-75 pl-8 py-1.5 cursor-pointer rounded-md"
                            (click)="navigate('Asset', asset.ID)">
                            <div class="flex flex-col w-full">
                                <p class="text-[14px] font-merium">{{asset.Name}}</p>
                                <p class="text-[10px] font-light">Matched by: <span class="text-lightGreen">{{asset.Field}}</span></p>
                            </div>
                        </li>
                    }
                </ul>
            }
        </ul>
    </div>
}