@if (displayedAsPage) {
    <form [formGroup]="form" class="flex w-full h-full items-center justify-center relative">
        <div class="flex flex-col font-poppins h-full items-center justify-center gap-4 w-[25%] ">
            <!-- <div class="flex flex-row">
                <p class="font-primaryBlack font-bold text-lightGreen text-[48px]">Firme</p>
                <p class="ml-[5px] font-light text-primaryBlack text-[48px]">Générale</p>
            </div> -->
            <img src="../../../../assets/WelcomePicture2.png" class="w-full absolute top-0 left-0"/>
            <div class="flex flex-col items-center justify-center w-full text-center word-break-all z-[2] mt-[200px]">
                <p class="text-[24px] text-primaryBlack font-medium text-balance">Sign in to your account</p>
                <p class="text-[16px] text-primaryBlack font-light text-balance">Enter your email and password to sign in to your account</p>
            </div>
            <div class="flex flex-col w-full items-center justify-center gap-4 z-[2]">    
                <div class="flex flex-col w-full">
                    @if (form.get('email')?.touched && form.get('email')?.invalid) {
                        <label for="email" class="text-lightRed font-regular text-[12px]">Email address (Required)</label>
                    } @else {
                        <label for="email" class="text-primaryBlack/70 font-regular text-[12px]">Email address</label>
                    }
                    <input formControlName="email" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="email@domain.com"/>
                </div>
        
                <div class="flex flex-col w-full">
                    @if (form.get('password')?.touched && form.get('password')?.invalid) {
                        <label for="password" class="text-lightRed font-regular text-[12px]">Password (Required)</label>
                    }
                    @else {
                        <label for="password" class="text-primaryBlack/70 font-regular text-[12px]">Password</label>
                    }
                    <label class="relative block">
                        <input class="placeholder:italic block w-full rounded-md py-2 px-4 rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] text-primaryBlack focus:outline-lightGreen" 
                                placeholder="Password" [type]="getInputType()" formControlName="password"/>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                            @if(passwordHidden){
                                <img src="../../../../assets/icons/ViewIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                            } @else {
                                <img src="../../../../assets/icons/HideIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                            }
                        </span>
                    </label>            
                </div>
    
                <div class="flex w-full items-center justify-center">
                    <app-button class="w-full"
                        text="Sign In"
                        width="100%"
                        icon="../../../assets/icons/SignInIcon.png"
                        [function]="signIn.bind(this)"
                    />
                </div>
            </div>
        
            <div class="flex w-full items-center justify-end">
                <p class="text-[12px] font-light text-primaryBlack hover:text-lightGreen transition-[0.5s] cursor-pointer">Forgot password?</p>
            </div>
        
            <div class="flex flex-row sm:w-[50%] xl:w-full justify-between items-center justify-center">
                <hr class="w-[35%] text-primaryBlack">
                <p class="text-[14px] text-primaryBlack font-light">Or continue with</p>
                <hr class="w-[35%] text-primaryBlack">
            </div>
        
            <div class="flex w-full items-center justify-center lg:w-[100%] xl:w-[35%] h-[45px]" id="buttonDiv"></div>
        
            <div class="flex flex-row w-full items-center justify-center">
                <p class="text-[14px] text-primaryBlack font-light">Don't have an account yet?</p>
                <p class="text-[14px] text-lightGreen font-bold ml-2 hover:text-darkGreen transition-[0.5s] cursor-pointer"
                   (click)="navigationService.navigateToSignUp()">Sign up</p>
            </div>
        </div>
    </form>
} @else {
    <div class="flex flex-col w-full h-full gap-8 p-8">
        <img src="../../../../assets/WelcomePicture2.png"/>
        <form [formGroup]="form" class="w-full flex 2xl:px-32 xl:px-14">
            <div class="flex flex-col font-poppins w-full h-full items-center justify-center gap-4">
                <!-- <div class="flex flex-row">
                    <p class="font-primaryBlack font-bold text-lightGreen 2xl:text-[72px] xl:text-[60px] xs:text-[34px]">Firme</p>
                    <p class="ml-[5px] font-light text-primaryBlack 2xl:text-[72px] xl:text-[60px] xs:text-[34px]">Générale</p>
                </div> -->
                <div class="flex flex-col items-center justify-center w-full text-center w-full text-center word-break-all gap-2">
                    <p class="2xl:text-[36px] xl:text-[24px] xs:text-[20px] text-primaryBlack font-medium text-balance">Sign in to your account</p>
                    <p class="2xl:text-[24px] xl:text-[14px] xs:text-[12px] text-primaryBlack font-light text-balance">Enter your email and password to sign in to your account</p>
                </div>
                <div class="flex flex-col w-full items-center justify-center gap-4">    
                    <div class="flex flex-col w-full">
                        @if (form.get('email')?.touched && form.get('email')?.invalid) {
                            <label for="email" class="text-lightRed font-regular text-[14px]">Email address (Required)</label>
                        } @else {
                            <label for="email" class="text-primaryBlack/70 font-regular text-[14px]">Email address</label>
                        }
                        <input formControlName="email" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 2xl:h-[70px] xl:h-[45px] xs:h-[45px] 2xl:text-[20px] xl:text-[16px] xs:text-[14px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="email@domain.com"/>
                    </div>
            
                    <div class="flex flex-col w-full">
                        @if (form.get('password')?.touched && form.get('password')?.invalid) {
                            <label for="password" class="text-lightRed font-regular text-[14px]">Password (Required)</label>
                        }
                        @else {
                            <label for="password" class="text-primaryBlack/70 font-regular text-[14px]">Password</label>
                        }
                        <label class="relative block">
                            <input class="placeholder:italic block w-full rounded-md py-2 px-4 rounded-md bg-white border-[1px] border-primaryBlack/20 2xl:h-[70px] xl:h-[45px] text-primaryBlack xs:h-[45px] 2xl:text-[20px] xl:text-[16px] xs:text-[14px] focus:outline-lightGreen" 
                                    placeholder="Password" [type]="getInputType()" formControlName="password"/>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                                @if(passwordHidden){
                                    <img src="../../../../assets/icons/ViewIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                                } @else {
                                    <img src="../../../../assets/icons/HideIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                                }
                            </span>
                        </label>            
                    </div>
        
                    <div class="flex w-full items-center justify-center">
                        <app-button class="w-full"
                            text="Sign In"
                            width="100%"
                            icon="../../../assets/icons/SignInIcon.png"
                            [function]="signIn.bind(this)"
                        />
                    </div>
                </div>
            
                <div class="flex w-full items-center justify-end">
                    <p class="text-[12px] font-light text-primaryBlack hover:text-lightGreen transition-[0.5s] cursor-pointer">Forgot password?</p>
                </div>
            
                <div class="flex flex-row w-full justify-between items-center justify-center">
                    <hr class="3xl:w-[40%] xs:w-[30%] text-primaryBlack">
                    <p class="text-[14px] text-primaryBlack font-light">Or continue with</p>
                    <hr class="3xl:w-[40%] xs:w-[30%] text-primaryBlack">
                </div>
            
                <div class="flex w-full items-center justify-center" id="buttonDiv"></div>
            
                <div class="flex flex-row w-full items-center justify-center">
                    <p class="3xl:text-[24px] xs:text-[16px] text-primaryBlack font-light">Don't have an account yet?</p>
                    <p class="3xl:text-[24px] xs:text-[16px] text-lightGreen font-bold ml-2 hover:text-darkGreen transition-[0.5s] cursor-pointer"
                       (click)="toggleSignUp()">Sign up</p>
                </div>
            </div>
        </form>
    </div>
    <!-- <form [formGroup]="form" class="flex w-full h-full items-center justify-center md:p-32 p-40">
        <div class="flex flex-col font-poppins w-full h-full items-center justify-center gap-4">
            <div class="flex flex-row">
                <p class="font-primaryBlack font-bold text-lightGreen 2xl:text-[72px] xl:text-[60px] xs:text-[34px]">Firme</p>
                <p class="ml-[5px] font-light text-primaryBlack 2xl:text-[72px] xl:text-[60px] xs:text-[34px]">Générale</p>
            </div>
            <div class="flex flex-col items-center justify-center w-full text-center w-full text-center word-break-all gap-2">
                <p class="2xl:text-[36px] xl:text-[24px] xs:text-[20px] text-primaryBlack font-medium text-balance">Sign in to your account</p>
                <p class="2xl:text-[24px] xl:text-[14px] xs:text-[12px] text-primaryBlack font-light text-balance">Enter your email and password to sign in to your account</p>
            </div>
            <div class="flex flex-col w-full items-center justify-center gap-4">    
                <div class="flex flex-col w-full">
                    @if (form.get('email')?.touched && form.get('email')?.invalid) {
                        <label for="email" class="text-lightRed font-regular text-[14px]">Email address (Required)</label>
                    } @else {
                        <label for="email" class="text-primaryBlack/70 font-regular text-[14px]">Email address</label>
                    }
                    <input formControlName="email" class="placeholder:italic w-full rounded-md bg-white border-[1px] border-primaryBlack/20 2xl:h-[70px] xl:h-[45px] xs:h-[45px] 2xl:text-[20px] xl:text-[16px] xs:text-[14px] p-4 text-primaryBlack focus:outline-lightGreen" placeholder="email@domain.com"/>
                </div>
        
                <div class="flex flex-col w-full">
                    @if (form.get('password')?.touched && form.get('password')?.invalid) {
                        <label for="password" class="text-lightRed font-regular text-[14px]">Password (Required)</label>
                    }
                    @else {
                        <label for="password" class="text-primaryBlack/70 font-regular text-[14px]">Password</label>
                    }
                    <label class="relative block">
                        <input class="placeholder:italic block w-full rounded-md py-2 px-4 rounded-md bg-white border-[1px] border-primaryBlack/20 2xl:h-[70px] xl:h-[45px] text-primaryBlack xs:h-[45px] 2xl:text-[20px] xl:text-[16px] xs:text-[14px] focus:outline-lightGreen" 
                                placeholder="Password" [type]="getInputType()" formControlName="password"/>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                            @if(passwordHidden){
                                <img src="../../../../assets/icons/ViewIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                            } @else {
                                <img src="../../../../assets/icons/HideIcon.png" class="size-[25px] opacity-50 hover:opacity-100 cursor-pointer z-[40]" (click)="showHidePassword()"/>
                            }
                        </span>
                    </label>            
                </div>
    
                <div class="flex w-full items-center justify-center">
                    <app-button class="w-full"
                        text="Sign In"
                        width="100%"
                        icon="../../../assets/icons/SignInIcon.png"
                        [function]="signIn.bind(this)"
                    />
                </div>
            </div>
        
            <div class="flex w-full items-center justify-end">
                <p class="text-[12px] font-light text-primaryBlack hover:text-lightGreen transition-[0.5s] cursor-pointer">Forgot password?</p>
            </div>
        
            <div class="flex flex-row w-full justify-between items-center justify-center">
                <hr class="3xl:w-[40%] xs:w-[30%] text-primaryBlack">
                <p class="text-[14px] text-primaryBlack font-light">Or continue with</p>
                <hr class="3xl:w-[40%] xs:w-[30%] text-primaryBlack">
            </div>
        
            <div class="flex w-full items-center justify-center" id="buttonDiv"></div>
        
            <div class="flex flex-row w-full items-center justify-center">
                <p class="3xl:text-[24px] xs:text-[16px] text-primaryBlack font-light">Don't have an account yet?</p>
                <p class="3xl:text-[24px] xs:text-[16px] text-lightGreen font-bold ml-2 hover:text-darkGreen transition-[0.5s] cursor-pointer"
                   (click)="toggleSignUp()">Sign up</p>
            </div>
        </div>
    </form> -->
}