import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import ApexCharts, { ApexOptions } from 'apexcharts'
import { AdminService } from '../../../services/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../../modals/warning-modal/warning-modal.component';
import { NgToastService } from 'ng-angular-popup';


@Component({
  selector: 'app-total-budget-widget',
  standalone: true,
  imports: [],
  templateUrl: './total-budget-widget.component.html',
  styleUrl: './total-budget-widget.component.css'
})
export class TotalBudgetWidgetComponent implements OnInit{

  @Input() paramsString : string = '';
  @Input() description : string = '';
  @Input() widgetId : string = '';

  @Output() widgetDeleted = new EventEmitter<void>();

  public data : any[] = [];

  public chartLabels : any[] = [];
  public budgets : number[] = [];

  constructor(private adminService : AdminService,
    private modalService : NgbModal,
    private toast : NgToastService
  ) { }

  ngOnInit(): void {
    this.getWidgetData();
  }

  public getWidgetData() : void {
    this.adminService.getWidget(this.paramsString).subscribe(
      (response : any[]) => {
        this.data = response;
        this.data.map((element) => {
          this.chartLabels.push(element.Name);
          this.budgets.push(element.Budget)
        });

        this.loadChart();
      },
      (error : HttpErrorResponse) => {
        console.log("Error while fetching data for filtered projects widget, ", error.message);
      }
    )
  }

  loadChart(): void {
    const totalBudget = this.budgets.reduce((acc, current) => acc + current, 0);
    const percentages = this.budgets.map(budget => parseFloat((budget / totalBudget * 100).toFixed(2)));
  
    const options: ApexOptions = {
      series: percentages,
      chart: {
        height: 720,
        width: "100%",
        type: 'pie',
      },
      labels: this.chartLabels,
      tooltip: {
        enabled: true,
        y: {
          formatter: (val, opts) => {
            const index = opts.dataPointIndex;
            const label = this.chartLabels[index]; // Access labels directly from component's property
            const budget = this.budgets[index];
            return `$${budget.toLocaleString()}`;
          }
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontFamily: 'Poppins, sans-serif',
          fontSize: '10px',
        }
      },
      legend: {
        position: 'bottom',
        fontSize: '10px',
        fontFamily: 'Poppins, sans-serif'
      }
    };
  
    const chart = new ApexCharts(document.querySelector('#pie-chart'), options);
    chart.render();
  }

  public deleteWidget() : void {
    const modalRef = this.modalService.open(
      WarningModalComponent, 
      { backdrop : "static", keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.adminService.deleteWidget(this.widgetId).subscribe(
          (response : any) => {
            console.log(response);
            this.widgetDeleted.emit();
            this.toast.success("Widget successfully deleted!", "Success!", 3000);
          },
          (error : HttpErrorResponse) => {
            console.log(error);
            this.toast.danger("Error while deleting widget!", "Error!", 3000);
          }
        )
      }
    )
  }
  
}
