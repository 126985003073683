<div class="flex flex-col w-full h-full rounded-md bg-primaryWhite p-4 gap-4">
    <div class="flex flex-row w-full items-center justify-between">
        <div class="flex flex-row w-full items-center gap-2">
            <img src="../../../assets/icons/IncomeIcon.png" class="size-[26px] scale-y-[-1]">
            <p class="text-[24px] leading-56 font-medium text-primaryBlack/60 uppercase tracking-widest">Incomes</p> 
        </div>
        <div class="flex flex-row w-full justify-end">
            <app-button
                [primary]=false
                [secondary]=true
                text="Add new"
                width="auto"
                icon="../../../assets/icons/PlusIcon.png"
                [function]="addNewIncome.bind(this)"
            />
        </div>
    </div>
    <div class="flex flex-col w-full">
        <p class="text-[14px] font-medium text-primaryBlack/60 uppercase tracking-wide">Total company income</p> 
        <p class="text-[32px] xl:text-[46px] font-bold text-lightGreen">${{totalIncome}}</p>
    </div>
    <hr class="text-primaryBlack/40"/>
    <div class="flex flex-col w-full gap-2">
        <p class="font-light text-[16px] text-primaryBlack/50">Here is a list of all your incomes</p>
        <div class="flex h-[45px] relative">
            <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
            </div>
            <input [(ngModel)]="searchText" (input)="searchIncomes()" type="text" id="table-search-users" class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-80 bg-primaryWhite focus:ring-lightGreen focus:border-lightGreen" placeholder="Search for incomes...">
        </div>
    </div>
    <div class="relative flex w-full flex-col overflow-x-auto border-[1px] sm:rounded-lg lg:rounded-md xl:rounded-md font-poppins">
        @if (isLoading) {
            <div role="status" class="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
                @for (skeleton of incomesCount; track $index) {
                    <div class="flex h-20 items-center justify-between">
                        <div class="flex flex-row items-center gap-4">
                            <div class="flex items-center justify-center size-[40px] bg-gray-300 rounded-md dark:bg-gray-700">
                                <svg class="w-4 h-4 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                                    <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"/>
                                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"/>
                                </svg>
                            </div>
                            <div class="h-4 bg-gray-300 rounded-full dark:bg-gray-600 w-48"></div>
                        </div>
                        <div class="w-32 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                        <div class="w-16 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                        <div class="flex flex-col gap-4">
                            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                        </div>
                    </div>
                }
                <span class="sr-only">Loading...</span>
            </div>            
        } @else {
            @if (incomes != null) {
                @if(incomes.length > 0) {
                    <table class="w-full text-left rtl:text-right">
                        <thead class="text-sm md:text-md xl:text-sm 2xl:text-md text-primaryBlack uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Payment date
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Actions
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Details
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (income of processedIncomes; track $index){
                                <tr class="bg-primaryWhite h-[45px] border-b text-sm md:text-md lg:text-md xl:text-md 2xl:text-lg items-center transition-[0.5s]">
                                    <td scope="row" class="flex flex-col px-6 py-4 max-w-[150px] whitespace-nowrap dark:text-white img-container">
                                        <div class="font-semibold truncate">{{income.Name}}</div>
                                        <div class="font-light text-sm md:text-sm lg:text-sm xl:text-sm 2xl:text-md truncate">{{income.Payer}}</div>
                                    </td>
                                    <td class="px-6">
                                        {{income.Ammount}}
                                    </td>
                                    <td class="px-6">
                                        <div class="">{{income.parsedPaymentDate}}</div>
                                    </td>
                                    <td class="px-6 gap-2">
                                        <div class="flex flex-row justify-center items-center gap-2 group cursor-pointer transition-[0.5s] p-2 bg-lightRed/20 rounded-md hover:bg-lightRed/35"
                                            (click)="deleteIncome(income.ID)">
                                            <img src="../../../assets/icons/TrashIcon.png" class="size-[20px]  icon-filter-red"/> 
                                            <p class="text-darkRed text-[16px]">Delete</p>
                                        </div>
                                        <div class="flex flex-row justify-center items-center gap-2 group cursor-pointer transition-[0.5s] mt-2 p-2 bg-lightYellow/25 hover:bg-lightYellow/50 rounded-md"
                                            (click)="editIncome(income)">
                                            <img src="../../../assets/icons/EditIcon.png" class="size-[20px]"/> 
                                            <p class="text-primaryBlack text-[16px]">Edit</p>
                                        </div>
                                    </td>
                                    <td class="px-6 gap-2" (click)="viewIncome(income)">
                                        <p class="cursor-pointer text-primaryBlack underline font-medium text-[16px] hover:text-lightGreen transition-[0.5s]">View</p>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                } @else {  
                    <div class="flex w-full p-4">
                        <p class="text-primaryBlack font-semibold text-[24px]">
                            There is no incomes to display. <br>
                            <span class="font-light text-[16px]">You can register your first income by pressing the <span class="font-medium text-lightGreen">"Add new"</span> button.</span>
                        </p>
                    </div>
                }
            } @else {  
                <div class="flex w-full p-4">
                    <p class="text-primaryBlack font-semibold text-[24px]">
                        There is no incomes to display. <br>
                        <span class="font-light text-[16px]">You can register your first income by pressing the <span class="font-medium text-lightGreen">"Add new"</span> button.</span>
                    </p>
                </div>
            }
        }
    </div>
</div>