import { Component, HostListener, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CompanyService } from '../../services/company.service';
import { GlobalSearchResult } from '../../models/globalSearchResult';
import { Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetHistoryModalComponent } from '../modals/asset-history-modal/asset-history-modal.component';
import { AssetsService } from '../../services/assets.service';

@Component({
  selector: 'app-global-search',
  standalone: true,
  imports: [FormsModule, RouterModule],
  templateUrl: './global-search.component.html',
  styleUrl: './global-search.component.css'
})
export class GlobalSearchComponent implements OnInit{
  public globalDropdownVisible : boolean = false;
  public searchText : string = '';

  public employeesVisible : boolean = true;
  public projectsVisible : boolean = true;
  public assetsVisible : boolean = true;
  public assetDefaultPicture: boolean = false;

  public searchResult? : GlobalSearchResult;
  public assets : any[] = [];
  public employees : any[] = [];
  public projects : any[] = [];

  constructor(private companyService : CompanyService,
    private router : Router,
    private modalService : NgbModal,
    private assetService : AssetsService
  ){}

  ngOnInit(): void {
    
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: MouseEvent) {
    this.globalDropdownVisible = false;
  }

  public navigate(category : string, id : string) {
    if(category === "Employee") this.router.navigate([`/viewUser/${id}`]);
    if(category === "Project") this.router.navigate([`/project/${id}`]);
    if(category === "Asset"){
      this.router.navigate([`/inventory`]);
      const modalRef = this.modalService.open(
        AssetHistoryModalComponent,
        { backdrop : 'static', keyboard : true }
      );
      
      this.assetService.getById(id).subscribe(
        (response : any) => {
          this.assetDefaultPicture = response.DefaultPicture
        }
      )

      modalRef.componentInstance.assetId = id;
      modalRef.componentInstance.defaultPicture = this.assetDefaultPicture;
    }
  }

  public showMenu(category : string) : void {
    if(category === 'Employees') this.employeesVisible = !this.employeesVisible;
    if(category === 'Projects') this.projectsVisible = !this.projectsVisible;
    if(category === 'Assets') this.assetsVisible = !this.assetsVisible;
  }

  public globalSearch() {
    if(this.searchText.length > 2) {
      this.globalDropdownVisible = true;
      this.companyService.globalSearch(this.searchText).subscribe(
        (response : GlobalSearchResult) => {
          this.searchResult = response;
          this.assets = this.searchResult.Assets;
          this.employees = this.searchResult.Employees;
          this.projects = this.searchResult.Projects;
        }
      )
    } else {
      this.globalDropdownVisible = false;
    }
  }
}
