<div class="flex flex-col w-full h-full items-start font-poppins p-2 gap-2">
    <div class="flex flex-row w-full gap-2 items-center">
        <a [routerLink]="['/projects']">
            <img src="../../../../assets/icons/BackIcon.png"  class="size-[30px] cursor-pointer"/>
        </a>
        <p class="text-[24px] font-semibold text-primaryBlack">Project details</p> 
    </div>
    <div class="border-b border-gray-200 dark:border-gray-700 mb-2">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li class="me-2" (click)="toggleGeneral()">
                <a class="inline-flex items-center justify-center p-4 cursor-pointer transition-[0.5s] rounded-t-lg group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showGeneral,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showGeneral}">
                    <svg class="w-4 h-4 me-2"
                        [ngClass]="{'text-lightGreen':showGeneral,
                            'group-hover:text-bg-primaryBlack/60' : !showGeneral}"
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z"/>
                    </svg>
                    General
                </a>
            </li>
            <li class="me-2" (click)="togglePositions()">
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showPositions,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showPositions}">                    
                    <svg class="w-4 h-4 me-2"
                        [ngClass]="{'text-lightGreen':showPositions,
                            'group-hover:text-bg-primaryBlack/60' : !showPositions}" 
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M4 12H3V8C3 6.89543 3.89543 6 5 6H9M4 12V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V12M4 12H10M20 12H21V8C21 6.89543 20.1046 6 19 6H15M20 12H14M14 12V10H10V12M14 12V14H10V12M9 6V5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V6M9 6H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Positions
                </a>
            </li>
            <li class="me-2" (click)="toggleMembers()">
                <a class="inline-flex items-center justify-center p-4 hover:border-b-2 hover:border-primaryBlack/30 cursor-pointer transition-[0.5s] rounded-t-lg hover:text-primaryBlack/60 group"
                    [ngClass]="{'text-lightGreen border-b-2 border-lightGreen' : showMembers,
                                'hover:text-primaryBlack/60 hover:border-b-2 hover:border-primaryBlack/30' : !showMembers}">                    
                    <svg class="w-4 h-4 me-2"
                        [ngClass]="{'text-lightGreen':showMembers,
                            'group-hover:text-bg-primaryBlack/60' : !showMembers}"  
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                        <circle cx="9.00098" cy="6" r="4" fill="currentColor"/>
                        <ellipse cx="9.00098" cy="17.001" rx="7" ry="4" fill="currentColor"/>
                        <path d="M20.9996 17.0005C20.9996 18.6573 18.9641 20.0004 16.4788 20.0004C17.211 19.2001 17.7145 18.1955 17.7145 17.0018C17.7145 15.8068 17.2098 14.8013 16.4762 14.0005C18.9615 14.0005 20.9996 15.3436 20.9996 17.0005Z" fill="currentColor"/>
                    </svg>
                    Members
                </a>
            </li>
        </ul>
    </div>
    @if (showGeneral) {
        <div class="flex flex-col h-auto w-full items-start justify-between justify-start gap-4">
            <div class="flex flex-row w-full items-center h-auto gap-4">
                <div class="flex flex-col w-full xl:w-full rounded-md bg-primaryWhite p-4 gap-2 relative">
                    <div class="flex flex-row w-full items-end justify-between">
                        <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 uppercase tracking-wide">General</p>
                        @if (!editing) {
                            <button class="flex items-center justify-center group"
                                    (click)="toggleEditing('General')">
                                <div class="flex flex-row gap-2 items-center">
                                    <img src="../../../assets/icons/EditIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                                    <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Edit</p>
                                </div>
                            </button>
                        } @else {
                            <button class="flex items-center justify-center group"
                                    (click)="toggleEditing('General')">
                                <div class="flex flex-row gap-2 items-center">
                                    <img src="../../../../assets/icons/CloseIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                                    <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Cancel</p>
                                </div>
                            </button>
                        }
                    </div>
                    @if(!editing){
                        <p class="font-semibold text-[32px] xl:text-[46px] text-primaryBlack">{{project?.Name}}</p>
                        <hr class="w-full text-primaryBlack/50 mb-1">
                        <div class="flex flex-row items-center w-full gap-2">
                            <img src="../../../assets/icons/DateIcon.png" class="size-[16px] xl:size-[20px]"/>
                            <p class="text-[16px] xl:text-[20px] font-light text-primaryBlack">{{startDateParsed}} - {{endDateParsed}}</p>
                        </div>
                        <p class="text-[16px] xl:text-[20px] font-light text-primaryBlack">Duration : <span class="font-medium">{{project?.ProjectDuration}} days</span></p>
                        <p class="text-[16px] xl:text-[20px] font-light text-primaryBlack">
                            Status: 
                            <span [ngClass]="{'text-lightGreen' : project?.ProjectStatus == 'Active' || project?.ProjectStatus == 'Successfully archived',
                                                'text-darkBlue' : project?.ProjectStatus == 'Scheduled',
                                                'text-lightHoverGreen' : project?.ProjectStatus == 'Finished',
                                                'text-lightYellow' : project?.ProjectStatus == 'Paused'}"
                                    class="font-medium">
                                {{project?.ProjectStatus}}
                            </span>
                        </p>
                    } @else {
                        <input class="placeholder:italic w-[50%] rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen" 
                                [(ngModel)]="project.Name" (ngModelChange)="onFieldChange('name', $event)" [placeholder]="project?.Name"/>
                        <hr class="w-full text-primaryBlack/50 mb-1">
                        <div class="flex flex-col w-full">
                            <label for="foundationDate" class="text-primaryBlack/70 font-regular text-[12px]">Start date</label>
                            <label for="foundationDate" class="text-primaryBlack/70 font-regular text-[12px]">Current value: <span class="font-medium">{{startDatePresentationWhenEditing}}</span></label>
                            <input [(ngModel)]="project.StartDate" (ngModelChange)="onFieldChange('startDate', $event)" type="date" 
                                 class="w-[30%] rounded-md bg-white border-[1px] border-primaryBlack/20 h-[45px] p-4 text-primaryBlack focus:outline-lightGreen">
                        </div>
                        <div class="flex flex-row gap-1.5 items-center">
                            <p class="text-[16px] xl:text-[14px] font-light text-primaryBlack">Duration : </p>
                            <input type="number" [(ngModel)]="project.ProjectDuration" (ngModelChange)="onFieldChange('projectDuration', $event)" 
                                    class="h-[45px] w-[200px] rounded-md bg-white border-[1px] border-primaryBlack/20 p-4 text-primaryBlack focus:outline-lightGreen">
                            <p class="text-[16px] xl:text-[14px] font-medium text-primaryBlack">days </p>
                        </div>
                        <div class="flex flex-row items-center gap-1.5">
                            <p class="text-[16px] xl:text-[14px] font-light text-primaryBlack">Status:</p>
                            <app-dropdown-menu class="z-[1]"
                                width="100%"
                                inputPlaceHolder="Select status"
                                [data]="['Active', 'Finished', 'Paused', 'Scheduled']"
                                (selectionChanged)="onFieldChange('projectStatus',$event)"
                            />
                        </div>
                        <div class="flex flex-row w-full items-center justify-end">
                            <div class="flex flex-row w-full justify-end">
                                <button class="flex py-2 px-4 text-[16px] rounded-md bg-primaryBlack hover:bg-lightGreen text-white transition-[0.5s]"
                                        (click)="save()">
                                    Save
                                </button>
                            </div>
                        </div>
                    }
                </div>
                <div class="flex flex-col w-full h-full rounded-md bg-primaryWhite p-4 gap-2">
                    <div class="flex flex-row w-full items-end justify-between">
                        <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 uppercase tracking-wide">Description</p>
                        @if (!editingDescription) {
                            <button class="flex items-center justify-center group"
                                    (click)="toggleEditing('Description')">
                                <div class="flex flex-row gap-2 items-center">
                                    <img src="../../../assets/icons/EditIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                                    <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Edit</p>
                                </div>
                            </button>
                        } @else {
                            <button class="flex items-center justify-center group"
                                    (click)="toggleEditing('Description')">
                                <div class="flex flex-row gap-2 items-center">
                                    <img src="../../../../assets/icons/CloseIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                                    <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Cancel</p>
                                </div>
                            </button>
                        }
                    </div>
                    @if(!editingDescription){              
                        <p class="text-[12px] 2xl:text-[16px] font-regular mt-4">{{project?.Description}}</p>
                    } @else {
                        <textarea [(ngModel)]="tmpDescription" class="w-full text-wrap text-[12px] min-h-[150px] max-h-[200px] rounded-md bg-white border-[1px] border-primaryBlack/20 h-full p-2 text-primaryBlack focus:outline-lightGreen"
                                [placeholder]="project?.Description"></textarea>                              
                        <div class="flex flex-row w-full items-center justify-end">
                            <div class="flex flex-row w-full justify-end">
                                <button class="flex py-2 px-4 text-[16px] rounded-md bg-primaryBlack hover:bg-lightGreen text-white transition-[0.5s]"
                                        (click)="save()">
                                    Save
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div class="flex flex-row w-full h-auto gap-4">
                <div class="flex flex-col w-full xl:w-[50%] h-full xl:justify-between rounded-md bg-white p-4 relative gap-2 xl:gap-4">
                    <div class="flex flex-row w-full items-center justify-between">
                        <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 uppercase tracking-wide">Budget</p>
                        @if(editingBudget){
                            <button class="flex items-center justify-center group" (click)="toggleEditing('Budget')">
                                <div class="flex flex-row gap-2 items-center">
                                    <img src="../../../assets/icons/CloseIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                                    <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Cancel</p>
                                </div>
                            </button>
                        } @else {
                            <button class="flex items-center justify-center group" (click)="toggleEditing('Budget')">
                                <div class="flex flex-row gap-2 items-center">
                                    <img src="../../../assets/icons/EditIcon.png" class="size-[16px] opacity-40 group-hover:opacity-100 transition-[0.5s]">
                                    <p class="text-[14px] text-primaryBlack/40 group-hover:text-primaryBlack transition-[0.5s] ">Edit</p>
                                </div>
                            </button>
                        }
                    </div>
                    <div class="flex flex-row w-full items-center justify-between">
                        @if (editingBudget) {
                            <input type="number" [(ngModel)]="project.Budget" (ngModelChange)="onFieldChange('budget', $event)" 
                            class="h-[45px] w-[200px] rounded-md bg-white border-[1px] border-primaryBlack/20 p-4 text-primaryBlack focus:outline-lightGreen">                    
                        } @else {
                            <p class="text-[32px] xl:text-[46px] font-bold text-lightGreen">${{project?.Budget}}</p>
                        }
                        <img src="../../../assets/icons/IncomeIcon.png" class="size-[75px] xl:size-[55px]">
                    </div>
                    <p class="text-[12px] xl:text-[16px] font-light text-primaryBlack">The initial budget of the project</p>
                    @if (editingBudget) {
                        <div class="flex flex-row w-full items-center justify-end">
                            <div class="flex flex-row w-full justify-end">
                                <button class="flex py-2 px-4 text-[16px] rounded-md bg-primaryBlack hover:bg-lightGreen text-white transition-[0.5s]"
                                        (click)="save()">
                                    Save
                                </button>
                            </div>
                        </div>
                    }
                </div>
                <div class="flex flex-col w-full xl:w-[50%] xl:justify-between h-full rounded-md bg-white p-4 gap-2 xl:gap-4">
                    <p class="text-[14px] leading-56 font-medium text-primaryBlack/60 uppercase tracking-wide">Expenses</p> 
                    <div class="flex flex-row w-full items-center justify-between">
                        <p class="text-[32px] xl:text-[46px] font-bold text-lightRed">$4000</p>
                        <img src="../../../assets/icons/ExpenseIcon.png" class="size-[75px] xl:size-[55px]">
                    </div>
                    <p class="text-[12px] xl:text-[16px] font-light text-primaryBlack">Current project implementation costs</p>
                </div>
            </div>
        </div>
        <div class="flex flex-col w-full gap-2 mt-2">
            <p class="text-[24px] text-primaryBlack">Danger zone</p>
            <div class="flex flex-col w-full rounded-md border-[1px] border-lightRed p-4">
                <div class="flex flex-row w-full items-center justify-between">
                    <div class="flex flex-col w-full">
                        <p class="text-primaryBlack font-medium text-[18px]">Delete project</p>
                        <p class="text-primaryBlack text-[14px]">This action implies the complete deletion of the project from the system, as well as all the positions that existed on it.</p>
                    </div>
                    <button class="flex px-4 py-2 items-center justify-center text-lightRed rounded-md border-[1px] border-lightRed w-[220px]
                                    hover:bg-lightRed hover:text-primaryWhite transition-[0.5s]"
                            (click)="deleteProject()">
                        <p>Delete project</p>
                    </button>
                </div>
            </div>
        </div>
    }
    @if(showPositions){
        <div class="flex flex-col w-full rounded-md bg-white p-4 gap-4 relative overflow-y-auto">
            <div class="flex flex-row w-full items-center gap-4">
                <div class="flex flex-row items-center gap-4">
                    <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">POSITIONS</p>
                    <button class="flex gap-2 p-2 rounded-md bg-darkGreen items-center justify-center hover:bg-lightGreen transition-[0.5s]"
                            (click)="openAddPositionModal()">
                        <img src="../../../assets/icons/PlusIcon.png" class="size-[16px] nav_menu_icon">
                        <p class="text-[12px] text-primaryWhite">Add new</p>
                    </button>
                </div>
                <div class="flex h-[45px] relative">
                    <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input [(ngModel)]="searchPositionsText" (input)="searchPositions()" type="text" id="table-search-users" class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-80 bg-primaryWhite focus:ring-lightGreen focus:border-lightGreen" placeholder="Search for positions">
                </div>
            </div>
            <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                @for (position of filteredPositions; track $index) {
                    @if(position.Employee === "000000000000000000000000"){
                        <app-position-card
                            [positionId]="position.ID"
                            [primarySkillId]="position.PrimarySkill"
                            [requiredSkillLevelId]="position.SkillLevel"
                            [employee]="position.Employee"
                            [cost]="position.EmployeeCostPerHour"
                            [professionId]="position.Profession"
                            [assigned]="false"
                            [projectId]="projectId"
                            [company]="fetchedCompany"
                            [requiresEditing]="position.requiresEditing"
                            (positionDeleted)="handlePositionDeleted()"
                            (employeeAssigned)="handleEmployeeAssigned()"
                        />
                    } @else {
                        <app-position-card
                            [positionId]="position.ID"
                            [primarySkillId]="position.PrimarySkill"
                            [requiredSkillLevelId]="position.SkillLevel"
                            [employee]="position.Employee"
                            [cost]="position.EmployeeCostPerHour"
                            [professionId]="position.Profession"
                            [assigned]="true"
                            [projectId]="projectId"
                            [requiresEditing]="position.requiresEditing"
                            (positionDeleted)="handlePositionDeleted()"
                            (employeeAssigned)="handleEmployeeAssigned()"
                        />
                    }
                }
            </div>
        </div>
    }
    @if (showMembers) {
        <div class="flex flex-col w-full rounded-md bg-white p-4 gap-4 relative overflow-y-auto">
            <div class="flex h-[45px] relative">
                <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="text" id="table-search-users" class="placeholder:italic block pt-2 ps-10 text-sm text-primaryBlack border border-lightGreen rounded-lg w-80 bg-primaryWhite focus:ring-lightGreen focus:border-lightGreen" placeholder="Search for members">
            </div>
            <div class="flex flex-col w-full gap-4">
                <div class="flex flex-col w-full gap-2">
                    <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide uppercase">Project manager</p>
                    <app-employee-card
                        [userId]="projectManagerId"
                        [displayedOnProject]="true"
                    />
                </div>
                <hr class="w-full text-primaryBlack/40">
                <div class="flex flex-col w-full gap-2">
                    <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">MEMBERS</p>
                    <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4">
                        @for (member of filteredEmployees; track $index) {
                            <app-employee-card
                                [userId]="member.employeeId"
                                [professionId]="member.professionId"
                                [displayedOnProject]="true"
                            />
                        }
                    </div>
                </div>
            </div>
        </div> 
    }
</div>