import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectsFilteredModalComponent } from '../widgets/projects-filtered-modal/projects-filtered-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AdminService } from '../../../services/admin.service';
import { Tooltip } from 'flowbite';

@Component({
  selector: 'app-add-widget-modal',
  standalone: true,
  imports: [],
  templateUrl: './add-widget-modal.component.html',
  styleUrl: './add-widget-modal.component.css'
})
export class AddWidgetModalComponent implements OnInit, AfterViewInit{

  @Input() hasTotalBudgetWidget : boolean = false;

  @Output() widgetAdded = new EventEmitter<void>();

  public widgets : string[] = ["Projects filtered by parameters", "Overall project budget"]

  constructor(private activeModalService : NgbActiveModal,
    private modalService : NgbModal,
    private adminService : AdminService
  ){}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.initTooltips();
  }
  
  public initTooltips() {
    const tooltipTriggerList = document.querySelectorAll('[data-tooltip-target]');
    tooltipTriggerList.forEach(function (tooltipTriggerEl) {
      if (tooltipTriggerEl instanceof HTMLElement) {
        new Tooltip(tooltipTriggerEl);
      }
    });
  }
  

  openProjectsFilteredModal() {
    this.goToProjectsFiltered();
  }

  public getFunction(widget : string) {
    if(widget == this.widgets[0]){
      return this.goToProjectsFiltered();
    } else if (widget == this.widgets[1]){
      return this.createOverallBudget();
    }
  }

  public createOverallBudget() : void {
    const paramString = "getOverallBudget";
    const body = {
      Name : "GetOverallBudget",
      Description : "Total budget",
      Querry : paramString
    }
    this.adminService.saveWidget(body).subscribe(
      (response : any) => {
        console.log("Widget saved successfully", response);
        this.widgetAdded.emit();
        this.closeModal();
      },
      (error : HttpErrorResponse) => {
        console.log("Error while saving widget, ", error.message);
      }
    );
  }

  public goToProjectsFiltered(): void {
    const modalRef = this.modalService.open(
      ProjectsFilteredModalComponent,
      { backdrop: 'static', keyboard: true }
    );
    modalRef.componentInstance.widgetAdded.subscribe(() => {
      this.widgetAdded.emit(); 
      this.closeModal();
    });
  }

  public closeModal() : void {
    this.activeModalService.close();
  }
}
