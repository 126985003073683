import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ProjectService } from '../../../services/project.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { PositionCardComponent } from "../../../components/position-card/position-card.component";
import { EmployeeCardComponent } from '../../../components/employee-card/employee-card.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreatePositionModalComponent } from '../../../components/modals/create-position-modal/create-position-modal.component';
import { NgToastService } from 'ng-angular-popup';
import { CompanyService } from '../../../services/company.service';
import { DropdownMenuComponent } from '../../../components/dropdown-menu/dropdown-menu.component';
import { FormsModule } from '@angular/forms';
import { WarningModalComponent } from '../../../components/modals/warning-modal/warning-modal.component';
import { NavigationService } from '../../../services/navigation.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-project-display',
  standalone: true,
  imports: [CommonModule, PositionCardComponent, RouterModule, EmployeeCardComponent, DropdownMenuComponent, FormsModule],
  templateUrl: './project-display.component.html',
  styleUrl: './project-display.component.css'
})
export class ProjectDisplayComponent implements OnInit{

  public project : any;
  public projectId : string = '';
  public fetchedCompany : any;
  public projectManagerId : string = '';
  
  public startDateParsed : string = '';
  public endDateParsed : string = '';

  public positions : any[] = [];
  public filteredPositions : any[] = [];
  public employees : any[] = [];
  public filteredEmployees : any[] = [];

  public editing : boolean = false;
  public editingBudget : boolean = false;
  public editingDescription : boolean = false;
  public selectedStatus : string = '';

  public originalProject: any = {};
  public changedValues: any = {};

  public showGeneral : boolean = true;
  public showPositions : boolean = false;
  public showMembers : boolean = false;

  public searchPositionsText : string = '';
  public searchMembersText : string = '';

  public tmpDescription : string = '';
  public startDatePresentationWhenEditing : string = '';

  constructor(private routes: ActivatedRoute,
    private projectService : ProjectService,
    private modalService : NgbModal,
    private toast : NgToastService,
    private authService : AuthService,
    private companyService : CompanyService,
    private navigationService : NavigationService
  ){}


  ngOnInit(): void {
    const idFromRoute = this.routes.snapshot.paramMap.get('id');
    if(idFromRoute != null){
      this.projectId = idFromRoute;
    }
    this.fetchCompany();
    this.fetchProject();
  }

  public fetchProject() : void {
    this.employees = [];
    this.projectService.get(this.projectId).subscribe(
      (response : any) => {
        this.project = response;
        this.projectManagerId = this.project.ProjectManager;
        this.originalProject = {
          Name: response.Name,
          StartDate: response.StartDate,
          EndDate: response.EndDate,
          Duration: response.ProjectDuration,
          Status: response.ProjectStatus
        };
        const startDate = new Date(this.project.StartDate);
        const endDate = new Date(this.project.EndDate);

        this.startDateParsed = startDate.toISOString().substring(0, 10);
        this.endDateParsed = endDate.toISOString().substring(0, 10);

        this.positions = response.Positions.map((position: any) => {
          const professions = sessionStorage.getItem('professions');
          let professionName;
          if(professions) {
            const professionsParsed = JSON.parse(professions);
            professionsParsed.map((profession : any ) => {
              if(profession.ID == position.Profession){
                professionName = profession.Name;
              }
            })
          }
        
          const requiresEditing = position.PrimarySkill === "000000000000000000000000" || position.SkillLevel === "000000000000000000000000";
          return {
            ...position,
            requiresEditing,
            professionName
          };
        }); 
        
        this.filteredPositions = this.positions;
        console.log("POZICIJE : ", this.filteredPositions)

        this.positions.map(position => {
          if(position.Employee !== "000000000000000000000000"){
            const body = {
              professionId : position.Profession,
              employeeId : position.Employee
            }
            this.employees.push(body);
            this.filteredEmployees = this.employees;
          }
        })

        console.log("Fetched Project: ",this.project);
        console.log("Project Members: ", this.employees);
      },
      (error : HttpErrorResponse) => {
        console.log("Error while fetching project, ", error.message);
      }
    );
  }

  public fetchCompany() : void {
    this.companyService.getCompany().subscribe(
      (response : any) => {
        this.fetchedCompany = response;
        console.log("Fetched Company: ",this.fetchedCompany);
      },
      (error : HttpErrorResponse) => {
        console.log("Error while fetching company, ", error.message);
      }
    )
  }

  openAddPositionModal() {
    this.goToAddPosiitonModal();
  }

  public goToAddPosiitonModal(): void {
    const modalRef = this.modalService.open(
      CreatePositionModalComponent,
      { backdrop: 'static', keyboard: true }
    );
    modalRef.componentInstance.company = this.fetchedCompany;
    modalRef.componentInstance.projectName = this.project.Name;
    modalRef.componentInstance.existingProject = true;
    modalRef.componentInstance.editingExistingPosition = false;
    modalRef.componentInstance.positonAdded.subscribe(
      (response : any) => {
        this.fetchProject();
        this.toast.success("Position created successfully!", "Success!", 3000)
      },
      (error : Error) => {
        this.toast.danger("An error occurred when creating position!", "Error!", 3000)
      }
    );
  }

  public handleEmployeeAssigned(): void {
    console.log("Employee assigned, updating project details...");
    this.toast.success("Employee successfully assigned!", "Success!", 3000);
    this.fetchProject();
  }

  public handlePositionDeleted() : void {
    console.log('Position deleted successfully!');
    this.toast.success("Position deleted successfully!", "Success!", 3000);
    this.fetchProject();
  }

  public toggleEditing(section : string) : void {
    if(section === "General") {
      this.editing = !this.editing; 
      const date = new Date(this.project.StartDate);
      this.startDatePresentationWhenEditing = date.toISOString().substring(0, 10);
    }
    if(section === "Budget") this.editingBudget = !this.editingBudget;
    if(section === "Description") this.editingDescription = !this.editingDescription;

  }

  public onFieldChange(field: string, newValue: any) {
    const date = new Date(this.project.StartDate);
    const newDateMillis = date.getTime();
    this.changedValues['startDate'] = newDateMillis;
    if (newValue !== this.originalProject[field]) {
      this.changedValues[field] = newValue;
    } else {
      delete this.changedValues[field];
    }
  }

  public save() {
    let queryParams = new URLSearchParams();
    console.log("NEMA", this.changedValues);
    for (const key in this.changedValues) {
      if(key === 'startDate'){
        const date = new Date(this.changedValues[key]);
        const newDate = date.getTime();
        this.changedValues[key] = newDate;
        queryParams.append(key, this.changedValues[key]);
      } else {
        queryParams.append(key, this.changedValues[key]);
      }
    }
  
    let queryString = queryParams.toString();
    
    console.log(queryString);
    this.projectService.edit(queryString, this.projectId).subscribe(
      (response : any) => {
        console.log("Project updated successfully", response);
        this.toast.success("Project updated successfully!", "Success!", 3000);
        this.editing = false;
        this.editingBudget = false;
        this.fetchProject();
      },
      (error : HttpErrorResponse) => {
        console.error("Error updating project", error.message);
        this.toast.danger("Failed to update project!", "Error!", 3000);
      }
    );
  }

  public toggleGeneral(){
    this.showGeneral = true;
    this.showPositions = false;
    this.showMembers  = false;
  }

  public togglePositions(){
    this.showGeneral = false;
    this.showPositions = true;
    this.showMembers  = false;
  }

  public toggleMembers(){
    this.showGeneral = false;
    this.showPositions = false;
    this.showMembers  = true;
  }

  public searchPositions() { 
    if(!this.searchPositionsText) {
      this.filteredPositions = this.positions;
    } else {
      this.filteredPositions = this.positions.filter(position => 
        position.professionName.toLowerCase().includes(this.searchPositionsText.toLowerCase())
      );
    }
  }

  public deleteProject() {
    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true },
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.projectService.deleteProject(this.projectId).subscribe(
          (response : any) => {
            console.log(response);
            this.toast.success("Project deleted successfully!", "Success!", 3000);
            this.navigationService.navigateToProjects();
          },
          (error : HttpErrorResponse) => {
            console.log(error.message);
            this.toast.danger("Failed to delete project!", "Error!", 3000);
          }
        );
      }
    )
  }

}
