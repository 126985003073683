import { Component, OnInit } from '@angular/core';
import { IncomesSectionComponent } from "../../../components/incomes-section/incomes-section.component";
import { ExpensesSectionComponent } from "../../../components/expenses-section/expenses-section.component";
import { CashflowService } from '../../../services/cashflow.service';
import { forkJoin } from 'rxjs';
import { Income } from '../../../models/income';
import { Expense } from '../../../models/expense';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-analytics',
  standalone: true,
  imports: [IncomesSectionComponent, ExpensesSectionComponent],
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.css'
})
export class AnalyticsComponent implements OnInit{

  public incomes : Income[] = []
  public expenses : Expense[] = []

  constructor(private cashFlowService : CashflowService){}

  ngOnInit(): void {
    this.getCashFlow();
  }

  public getCashFlow() : void {
    this.cashFlowService.getCashFlow().subscribe(
      (response : any) => {
        console.log("CASHFLOW: ", response);
        this.incomes = response.incomes;
        this.expenses = response.expenses;

        console.log("ALL INCOMES: ", this.incomes);
        console.log("ALL EXPENSES: ", this.expenses);

      },
      (error : HttpErrorResponse) => {
        console.log("Error while fetching cashflow, ", error);
      }
    )
  }
}
