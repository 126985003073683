import { Component, OnInit } from '@angular/core';
import { RouterOutlet, provideRouter } from '@angular/router';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SignInComponent } from './pages/adminPages/sign-in/sign-in.component';
import { HttpClientModule } from '@angular/common/http';
import { NgToastModule, ToasterPosition } from 'ng-angular-popup';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AuthService } from './services/auth.service';
import { LandingComponent } from "./pages/landing/landing.component";
// import { AppRoutingModule } from './app.routes';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavigationComponent, SignInComponent, NgToastModule, HttpClientModule, LandingComponent, CommonModule],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'frontend';
  ToasterPosition = ToasterPosition;

  public loggedIn : boolean = true;
  public user : any;
  public color : string | null = null;

  constructor(private authService : AuthService) {
  }

  ngOnInit(): void {
    this.authService.authStatus$.subscribe(status => {
      this.loggedIn = status;
      const domain = sessionStorage.getItem('domain');
      // this.authService.getUserInfo(domain).subscribe(
      //   (response : any) => {
      //     this.user = response;
      //     console.log("USERCINA APP: ", this.user);
      //   }
      // )
      // this.authService.getAdmin().subscribe(
      //   (respnose : any) => {
      //     this.user = respnose;
      //     console.log("USERCINA APP: ", this.user);
      //   }
      // )
    });
  }
}
