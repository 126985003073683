import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../auth.service';
import { NavigationService } from '../navigation.service';

export const roleGuard: CanActivateFn = (route, state) => {

  const navigationService = inject(NavigationService);
  const role = sessionStorage.getItem('Role');

  if(role == "Admin" || role == "Owner"){
    return true;
  } else {
    navigationService.navigateToUnauthorized();
    return false;
  }

};
