import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router,
    private _ngZone : NgZone
  ) { }
            
  public navigateToSignIn() : void{
    this.router.navigate(['signIn']);
  }

  public navigateToDashboard() : void{
    this.router.navigate(['dashboard']);
  }

  public navigateToSignUp() : void{
    this.router.navigate(['signUp']);
  }

  public navigateToUserProfile(userId : string) : void{
    this.router.navigate([`viewUser/${userId}`]);
  }
  
  public navigateToEmployees() : void {
    this.router.navigate(['employees']);
  }

  public navigateToProject(projectId : string) : void {
    this.router.navigate([`project/${projectId}`]);
  }
  
  public navigateToProjects() : void {
    this.router.navigate(['projects']);
  }

  public navigateToSettings() : void {
    this.router.navigate(['settings']);
  }

  public navigateToAssets() : void {
    this.router.navigate(['assets']);
  }

  public navigateToChooseCompany() : void {
    this._ngZone.run(() => {
      this.router.navigate(['choose']);
    })
  }

  public navigateToUnauthorized() : void {
    this.router.navigate(['unauthorized'])
  }

  public employeeNavigation(pagePath : string){
    var domain = sessionStorage.getItem('domain')
    var token = sessionStorage.getItem('token');
    this.router.navigate([`${domain}/${pagePath}/${token}`]);
  }

  public navigateToProfile() : void {
    var domain = sessionStorage.getItem('domain')
    this.router.navigate([`${domain}/profile`]);
  }

  // public navigateToProfileEmployee() : void {
  //   this.router.navigate([':domain/profile']);
  // }
}
