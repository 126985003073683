import { Component, ElementRef, OnInit } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { NavigationService } from '../../services/navigation.service';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { HttpClient, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { CompanyService } from '../../services/company.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { GlobalSearchComponent } from "../global-search/global-search.component";
import { ColorTranslator, Harmony, Mix } from 'colortranslator';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [ButtonComponent, CommonModule, RouterModule, FormsModule, GlobalSearchComponent],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.css'
})
export class NavigationComponent implements OnInit{

  public dropdownOpen: boolean = false;

  public activeWindow : string = '';

  //Administrators
  public showDashboard : boolean = true;
  public showEmployees : boolean = false;
  public showProjects: boolean = false;
  public showSettings: boolean = false;
  public showAssets : boolean = false;
  public showAnalytics : boolean = false;

  //Employees
  public showProfile : boolean = false;
  public showNotifications : boolean = true;
  public showVacancies : boolean = false;
  public showEmployeeAssets : boolean = false;
  public showFinance : boolean = false;
  public showEngagements : boolean = false;
  public isHoveredProfile : boolean = false;
  public isHoveredNotifications : boolean = false;
  public isHoveredVacancies : boolean = false;
  public isHoveredFinance : boolean = false;
  public isHoveredEngagements : boolean = false;
  public isHoveredAssets : boolean = false;

  public loggedUser : any;
  public isAdmin : boolean = false;
  public userLogged : boolean = true;
  public haveCompany : boolean = true;
  public companyChoosen : boolean = true;
  public lightText : boolean = false;
  public notifyForChanges : boolean = false;

  public fullName : string = '';
  public email : string = '';
  public userId : string = '';
  public company : any;
  public companyName : string = '';
  public numberOfCompanies : number = 0;

  constructor(public navigationService: NavigationService,
    public authService : AuthService,
    public router : Router,
    private companyService : CompanyService,
    private eRef: ElementRef
  ){}
  
  ngOnInit(): void {
    const storedCompanyId = sessionStorage.getItem('company');
    const storedCompanyName = sessionStorage.getItem('CompanyName');
    const storedFullName = sessionStorage.getItem('fullName');
    const storedEmail = sessionStorage.getItem('email');
    const storedIsAdmin = sessionStorage.getItem('isAdmin');
  
    if (storedCompanyId && storedCompanyName && storedFullName && storedEmail) {
      this.companyName = storedCompanyName;
      this.fullName = storedFullName;
      this.email = storedEmail;
      this.isAdmin = storedIsAdmin === 'true'; 
      this.getColors();
      this.companyChoosen = true;
      this.authService.companyStatusSubject.next(true);
    } else {

      this.companyService.companyChangesRequired$.subscribe(
        status => {
          console.log("POTREBNE PROMENE: ", this.notifyForChanges);
          this.notifyForChanges = status;
        }
      )

      this.authService.authStatus$.subscribe(status => {
        this.userLogged = status;
        if (status) {
          this.loadUserInfo();
        }
      });
  
      this.companyService.companyNameSubject.subscribe((companyName: string | null) => {
        if (companyName) {
          this.companyName = companyName;
        }
      });
  
      this.authService.companyStatusSubject.subscribe((companyStatus: boolean) => {
        this.companyChoosen = companyStatus;
      });
    }
    this.getColors();

  }
  
  private loadUserInfo() {
    const domain = sessionStorage.getItem('domain');
    // this.authService.getUserInfo(domain).subscribe(
    this.authService.getAdmin().subscribe(
      user => {
        this.loggedUser = user;
        
        sessionStorage.setItem('adminId', this.loggedUser.ID)
        sessionStorage.setItem('Role', this.loggedUser.Role);
        sessionStorage.setItem('fullName', user.FirstName + ' ' + user.LastName);
        sessionStorage.setItem('email', user.Email);
  
        if (this.loggedUser.Role === "Admin") {
          this.isAdmin = true;
          this.getColors();
          sessionStorage.setItem('isAdmin', 'true');
        } else {
          sessionStorage.setItem('isAdmin', 'false');
        }
  
        this.fullName = user.FirstName + " " + user.LastName;
        this.email = user.Email;
        this.userId = user.ID || user.EmployeeID || "000000000000000000000000";
        
        this.numberOfCompanies = user.Company.length;
        console.log("BROJ KOMPANIJA: ", this.numberOfCompanies);

        this.companyService.companyCreated$.subscribe(
          counterChanged => {
            this.numberOfCompanies = this.numberOfCompanies + 1;
          }
        )

        if (user.Company.length === 1) {
          sessionStorage.setItem('company', user.Company[0]);
          this.authService.companyStatusSubject.next(true);
  
          this.companyService.getCompany().subscribe(response => {
            this.company = response;
            this.companyService.setCompanyName(this.company.Name);
            sessionStorage.setItem('CompanyName', this.company.Name);
  
            sessionStorage.setItem("techStack", JSON.stringify(response.TechStack));
            sessionStorage.setItem("experienceLevels", JSON.stringify(response.ExperienceLevels));
            sessionStorage.setItem("professions", JSON.stringify(response.Professions));
  
            if (response.ExperienceLevels.length === 0 || response.TechStack.length === 0 || response.Professions.length === 0) {
              this.notifyForChanges = true;
              this.navigationService.navigateToSettings();
              sessionStorage.setItem('settings', "Not Completed");
            } else {
              this.companyChoosen = true;
              this.navigationService.navigateToDashboard();
              this.activeWindow = 'Dashboard'
            }
          });
        } else {
          if (!this.companyChoosen) {
            this.navigationService.navigateToChooseCompany();
          }
        }
      }
    );
  }
  
  public openChooseCompanyPage() : void {
    this.navigationService.navigateToChooseCompany();
    this.activeWindow = '';
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;

    if (this.dropdownOpen) {
      document.addEventListener('click', this.closeDropdownFromOutside.bind(this), true);
    } else {
      document.removeEventListener('click', this.closeDropdownFromOutside.bind(this), true);
    }
  }

  closeDropdownFromOutside(event : Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
      document.removeEventListener('click', this.closeDropdownFromOutside.bind(this), true);
    }
  }

  public getColors(){
    if(this.isAdmin) {
      this.lightText = true;
    } else {
      const companyColor = sessionStorage.getItem('companyColor');
      if(companyColor){
        const color_hsl = new ColorTranslator(companyColor);
        console.log("HSL Color L: ", color_hsl.L)
        
        if(color_hsl.L >= 50) {
          this.lightText = false 
        } else {
          this.lightText = true;
        }
      }
    }
  }

  public setActiveWindow(window : string) : void {
    this.activeWindow = window;
  }

  public logout() : void {
    this.authService.logoutAdmin().subscribe(
      (response : any) => {
        console.log("Logging out...");
        this.authService.logOut();
      },
      (error : HttpErrorResponse) => {
        console.log("Error while logging out, ", error.message);
      }
    )
  }
}
