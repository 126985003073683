<div class="flex flex-col justify-start bg-white rounded-md p-6 gap-2">
    <div class="flex flex-row w-full items-center justify-between justify-center">
        <p class="text-[14px] font-medium text-primaryBlack/60 tracking-wide">ENGAGEMENT HISTORY</p>
    </div>
    <div class="flex flex-row gap-4 items-center mt-2">
        <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-active rounded-full me-1.5 flex-shrink-0"></span>Active</span>
        <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-scheduled rounded-full me-1.5 flex-shrink-0"></span>Scheduled</span>
        <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-paused rounded-full me-1.5 flex-shrink-0"></span>Paused</span>
        <span class="flex items-center text-sm font-medium text-primaryBlack"><span class="flex w-2.5 h-2.5 bg-finished rounded-full me-1.5 flex-shrink-0"></span>Finished</span>
    </div>
    <hr class="text-primaryBlack/50 my-2">
    <div class="flex flex-row w-full overflow-x-auto gap-4 py-2">
        @if (engagements !== null && engagements.length > 0) {
            @for(engagement of engagements; track $index){
                <app-project-card (click)="navigationService.navigateToProject(engagement.ProjectID)" 
                    [name]="engagement.ProjectName" 
                    [status]="engagement.ProjectStatus"/>
            }
        } @else {
            <div class="flex w-full">
                <p class="text-primaryBlack font-semibold text-[24px]">
                    There are no engagements to display. <br>
                    <span class="font-light text-[16px]">The employee is not assigned to any position on any project</span>
            </div>
        }
    </div>
</div>