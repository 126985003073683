<div [ngStyle]="{'background': !loggedIn ? 'white' :'linear-gradient(to top left, #39b36a 20%, #0A210F 80%)'}"
    class="flex fex-row w-[100vw] h-[100vh] p-4 gap-4 overflow-y-auto item-start justify-center relative bg-ligh">
  @if(loggedIn){
    <div class="flex w-[15%] items-start">
      <app-navigation class="w-full h-full"/>
    </div>
  }
  <div [ngClass]="{'p-4 bg-lightGrey rounded-2xl' : loggedIn}" 
      class="flex-1 grow w-[85%] h-full p-4 bg-lightGrey overflow-hidden overflow-y-auto">
    <router-outlet/>
    <ng-toast [position]="ToasterPosition.TOP_RIGHT"/>
  </div>
  <p class="text-primaryBlack/30 font-light text-[10px] absolute bottom-1 right-1">0.1.1-228</p>
</div>
