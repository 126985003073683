<div [ngClass]="{'hover:translate-y-[-7px] bg-primaryWhite':!displayedOnProject && !requiresEditing,
                'hover:translate-y-[-6px]  bg-lightYellow/20':!displayedOnProject && requiresEditing,
                'border-[1px] hover:border-lightGreen':displayedOnProject}"
    class="flex flex-col lg:size-[250px] hover:shadow-xl 4xl:size-[600px] 3xl:size-[400px] 2xl:size-[280px] md:size-[220px] sm:size-[200px] xs:size-[150px] overflow-y-auto items-center justify-center group
            rounded-md group cursor-pointer transition-[0.5s] gap-2 font-poppins relative"
    (click)="navigationService.navigateToUserProfile(userId)">
    <!-- <div class="flex flex-row w-auto absolute top-5 right-5 items-center justify-center">
        <div class="rounded-full size-[20px]" 
            [ngClass]="{'bg-finished' : employee,
                        'bg-scheduled' : admin,
                        'bg-paused' : owner,
                        'bg-active' : manager}"></div>
    </div> -->
    <div class="flex 4xl:size-[300px] 2xl:size-[130px] 3xl:size-[200px] sm:size-[70px] lg:size-[100px] xl:size-[120px] img-container rounded-full border-[1px] border-primaryBlack/20">
        <img [src]="employeePictureLink" class="rounded-full size-full"/>
    </div>
    @if (!displayedOnProject) {
        <div class="flex flex-col w-full items-center justify-center text-center">
            <p class="font-medium text-primaryBlack sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[32px] 4xl:text-[42px]">{{fullName}}</p>
            <p class="font-light text-primaryBlack sm:text-[10px] md:text-[12px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[18px] 4xl:text-[42px] uppercase tracking-widest">{{role}}</p>
        </div>
    } @else {
        <p class="font-medium text-primaryBlack sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[32px] 4xl:text-[42px]">{{displayName}}</p>
    }
    @if (!displayedOnProject) {
        @if (requiresEditing) {
            <div class="flex flex-col w-full items-center justify-center">
                <p class="text-[16px] font-semibold text-lightRed">UPDATE REQUIRED</p>
            </div>
        } @else {
            <div class="flex flex-col w-full items-center justify-center">
                <div class="flex flex-row w-full items-center justify-center">
                    <p class="sm:text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[32px] 4xl:text-[42px] font-bold text-lightGreen">${{grossSalary}}</p>
                    <p class="sm:text-[10px] md:text-[12px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] 3xl:text-[26px] font-light text-primaryBlack">/month (Gross)</p>
                </div> 
            </div>
        }
    } @else {
        <div class="flex flex-col w-full items-center justify-center">
            <p class="text-[20px] font-bold text-primaryBlack">{{profession}}</p>
        </div>
    }
</div>