import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../button/button.component';
import { CompanyService } from '../../../services/company.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-techstack-experience-modal',
  standalone: true,
  imports: [FormsModule, ButtonComponent],
  templateUrl: './add-techstack-experience-modal.component.html',
  styleUrl: './add-techstack-experience-modal.component.css'
})
export class AddTechstackExperienceModalComponent implements OnInit{

  @Output() companySettingsChanged = new EventEmitter<void>();

  @Input() text : string = ''
  @Input() placeholder : string = '';
  @Input() type : string = '';

  inputValue : string = '';
  stack : string[] = [];

  constructor(private modalService : NgbActiveModal,
    private companyService : CompanyService
  ){}
  
  ngOnInit(): void {
  }

  public saveStack() : void {
    if(this.inputValue){
      this.stack.push(this.inputValue);
      const body = {
        field : this.type,
        stack : this.stack
      }
      this.companyService.createStack(body).subscribe(
        (response : any) => {
          console.log(response);
          this.companySettingsChanged.emit();
          this.closeModal();
        },
        (error : HttpErrorResponse) => {
          console.log("Error while saving stack, ", error.message)
        }
      );
    }
  }

  public closeModal() : void {
    this.modalService.close();
  }
}
