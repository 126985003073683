import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CashflowService } from '../../services/cashflow.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Expense } from '../../models/expense';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';
import { NgToastService } from 'ng-angular-popup';
import { ButtonComponent } from '../button/button.component';
import { CreateExpenseModalComponent } from '../modals/create-expense-modal/create-expense-modal.component';
import { ViewIncomeOrExpenseModalComponent } from '../modals/view-income-or-expense-modal/view-income-or-expense-modal.component';
import { EditIncomeOrExpenseComponent } from '../modals/edit-income-or-expense/edit-income-or-expense.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-expenses-section',
  standalone: true,
  imports: [ButtonComponent, FormsModule],
  templateUrl: './expenses-section.component.html',
  styleUrl: './expenses-section.component.css'
})
export class ExpensesSectionComponent implements OnInit {

  @Input() expenses : Expense[] = [];

  public isLoading : boolean = true;
  public searchText : string = '';
  public totalExpense : number = 0;

  public processedExpenses : any[] = [];
  public incomesCount = new Array(10);
  public filteredExpenses : any[] = [];


  constructor(private cashflowService : CashflowService,
    private modalService : NgbModal,
    private toast : NgToastService
  ){}

  ngOnInit(): void {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['expenses'] && changes['expenses'].currentValue) {
      console.log("RECEIVED EXPENSES: ", this.expenses);
      this.processExpenses();
    }
  }

  public processExpenses() : void {
    this.isLoading = true;
    this.totalExpense = 0;
    this.processedExpenses = this.expenses.map(expense => {
      this.totalExpense += expense.amount
      const date = new Date(expense.date);
      const paymentDate = new Date(expense.paymentDate)
      const dateParsed = date.toISOString().substring(0, 10);
      const paymentDateParsed = paymentDate.toISOString().substring(0,10);
      return {
        ...expense,
        parsedCreationDate : dateParsed,
        parsedPaymentDate : paymentDateParsed
      };
    });
    this.filteredExpenses = this.processedExpenses;
    if(this.processedExpenses !== null && this.processedExpenses.length > 0){
      this.searchExpenses();
    }

    this.isLoading = false;
  }

  public getAllExpenses() : void {
    this.isLoading = true;
    this.totalExpense = 0;
    this.cashflowService.getAllExpenses().subscribe(
      (response : Expense[]) => {
        this.isLoading = false;
        this.processedExpenses = response.map(expense => {
          this.totalExpense += expense.amount
          const date = new Date(expense.date);
          const paymentDate = new Date(expense.paymentDate)
          const dateParsed = date.toISOString().substring(0, 10);
          const paymentDateParsed = paymentDate.toISOString().substring(0,10);
          return {
            ...expense,
            parsedCreationDate : dateParsed,
            parsedPaymentDate : paymentDateParsed
          };
        });
        this.filteredExpenses = this.processedExpenses;
        if(this.processedExpenses !== null && this.processedExpenses.length > 0){
          this.searchExpenses();
        }

        this.isLoading = false;
      },
      (error : HttpErrorResponse) => {
        this.isLoading = false;
        console.log("Error while fetching all expenses, ", error.message)
      }
    )
  }

  public searchExpenses(): void {
    if (!this.searchText) {
      this.processedExpenses = this.filteredExpenses;
    } else {
      this.processedExpenses = this.filteredExpenses.filter(expense =>
        expense.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
  }

  public deleteExpense(expenseId : string) :void {
    const modalRef = this.modalService.open(
      WarningModalComponent, 
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.confirmation.subscribe(
      () => {
        this.cashflowService.deleteExpense(expenseId).subscribe(
          (response : any) => {
            this.toast.success("Expense successfully deleted!", "Success!", 3000);
            this.getAllExpenses();
          },
          (error : HttpErrorResponse) => {
            this.toast.danger("Error while deleting expense!", "Error!", 3000);
            console.log("Error while deleting expense, ", error.message);
          }
        )
      }
    )
  }

  public addNewExpense() : void {
    const modalRef = this.modalService.open(
      CreateExpenseModalComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.expenseCreated.subscribe(
      () => {
        this.toast.success("Expense successfully created!", "Success!", 3000);
        this.getAllExpenses();
      }
    )

  }

  public viewExpense(expense : Expense) : void {
    const modalRef = this.modalService.open(
      ViewIncomeOrExpenseModalComponent,
      { backdrop : 'static', keyboard : true },
    )

    modalRef.componentInstance.isIncome = false;
    modalRef.componentInstance.expense = expense;
  }

  public editExpense(expense : Expense) : void {
    const modalRef = this.modalService.open(
      EditIncomeOrExpenseComponent,
      { backdrop : 'static', keyboard : true }
    );

    modalRef.componentInstance.isIncome = false;
    modalRef.componentInstance.expense = expense;
  }
}
