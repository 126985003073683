import { Component, Input, OnInit } from '@angular/core';
import { EmployeeService } from '../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-small-employee-card-content',
  standalone: true,
  imports: [],
  templateUrl: './small-employee-card-content.component.html',
  styleUrl: './small-employee-card-content.component.css'
})
export class SmallEmployeeCardContentComponent implements OnInit{
  @Input() FirstName : string = '';
  @Input() LastName : string = '';
  @Input() Role : string = ''
  @Input() DefaultPicture : boolean = true;
  @Input() EmployeeID : string = '';

  public employeePictureLink : any;

  constructor(private employeeService : EmployeeService,
    private sanitizer: DomSanitizer
  ){}

  ngOnInit(): void {
    this.getEmployeePicture();
  }

  public getEmployeePicture() {
    const company = sessionStorage.getItem('company');
    let companyId;
    let param;
    if(this.DefaultPicture) {
      param = "default";
      companyId = "firmegeneralepictures";
    } else {
      param = this.EmployeeID;
      companyId = company;
    }
    this.employeeService.getEmployeeImage(param, companyId).subscribe(
      (response : string) => {
        this.employeePictureLink = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response);
      },
      (error : HttpErrorResponse) => {
        console.log("Error while getting s3 image link, ", error.message);
      }
    )  }
  
}
