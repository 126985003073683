import { Component, OnInit } from '@angular/core';
import { EmployeesTableComponent } from '../../../components/employees-table/employees-table.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { ProjectCardComponent } from '../../../components/project-card/project-card.component';
import { Status } from '../../../models/project-status';
import { Project } from '../../../models/project';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddWidgetModalComponent } from '../../../components/modals/add-widget-modal/add-widget-modal.component';
import { AuthService } from '../../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectsWidgetComponent } from '../../../components/widgets/projects-widget/projects-widget.component';
import { NgToastService } from 'ng-angular-popup';
import { TotalBudgetWidgetComponent } from "../../../components/widgets/total-budget-widget/total-budget-widget.component";
import { CashflowWidgetComponent } from "../../../components/widgets/cashflow-widget/cashflow-widget.component";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [EmployeesTableComponent, ButtonComponent, ProjectsWidgetComponent, TotalBudgetWidgetComponent, TotalBudgetWidgetComponent, CashflowWidgetComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit{

  public hasTotalBudgetWidgetFlag : boolean = false;
  public companyId = sessionStorage.getItem('company');

  public widgets : any[] = [];
  public filteredWidgets : any[] = [];
  
  constructor(private modalService : NgbModal,
    private authService : AuthService,
    private toast : NgToastService,
  ){
    // this.authService.$refreshTokenReceived.subscribe(
    //   (response : any) => {
    //     this.fetchAdmin();
    //   }
    // )
  }

  ngOnInit(): void {
    this.fetchAdmin();
  }

  public fetchAdmin() : void {
    const domain = sessionStorage.getItem('domain')
    // this.authService.getUserInfo(domain).subscribe(
    this.authService.getAdmin().subscribe(
      (response : any) => {
        this.widgets = response.Widgets;
        console.log("WIDGETI: ", this.widgets);
        this.widgets.map((widget : any) => {
          if(widget.Name === 'GetOverallBudget') {
            this.hasTotalBudgetWidgetFlag = true;
          } 
        })
        this.filteredWidgets = this.widgets.filter(widget => 
          widget.Company.includes(this.companyId)
        )
        this.filterWidgets();
      },
      (error : HttpErrorResponse) => {
        console.log(error.message);
      }
    )
  }

  public handleWidgetDeleted() : void {
    this.fetchAdmin();
    this.filterWidgets();
  }

  public filterWidgets() {
    this.filteredWidgets = this.widgets.filter(widget => 
      widget.Company.includes(this.companyId)
    )

    console.log("FILTRIRANI: ", this.filteredWidgets);
  }

  openAddWidgetModal() {
    this.goToAddWidget();
  }

  public goToAddWidget(): void {
    const modalRef = this.modalService.open(
      AddWidgetModalComponent,
      { backdrop: 'static', keyboard: true }
    );
    console.log("Does admin have total budget widget?" , this.hasTotalBudgetWidgetFlag)
    modalRef.componentInstance.hasTotalBudgetWidget = this.hasTotalBudgetWidgetFlag;
    modalRef.componentInstance.widgetAdded.subscribe(
      (response : any) => {
        this.fetchAdmin();
        this.toast.success("Widget created successfully!", "Success!", 3000)
      }, (error : Error) => {
        this.toast.danger("Widget created successfully!", "Success!", 3000)
      }
    );
  }

}
