import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  // private apiServerUrl = "https://api.firmegenerale.cloud";
  private apiServerUrl = environment.apiServerUrl;

  constructor(private http: HttpClient) { }

  public getWidget(params : string) : Observable<any> {
    return this.http.get(`${this.apiServerUrl}/widget/${params}`);
  }

  public saveWidget(body : any) : Observable<any> {
    return this.http.post(`${this.apiServerUrl}/widget/saveWidgetMetaData`, body);
  }

  public deleteWidget(widgetId : string) {
    // const options = { headers : this.getHeaders() }
    return this.http.delete<any>(`${this.apiServerUrl}/widget/delete/${widgetId}`);
  }
}
