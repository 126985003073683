import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  private apiServerUrl = environment.apiServerUrl;

  constructor(private http : HttpClient) { }

  public getFilteredActivities(employeeId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/employee/generateActivityReport?employeeID=${employeeId}&startDate=1627995023586&endDate=1926052865177&durationMin=1&projectID=`);
  }

  public getAllForEmployee(employeeId : string) : Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/employee/getAllActivities?employeeID=${employeeId}`);
  }

  public reportPdf(employeeId : string, activities : any[]) : Observable<any>{
    const domain = sessionStorage.getItem('domain')
    return this.http.post<any>(`${this.apiServerUrl}/report/pdf?employeeID=${employeeId}&startDate=1726042208241&endDate=1826052865177&domain=${domain}&projectID=`, activities, { responseType: 'text' as 'json' })
  }
}
