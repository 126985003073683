<div class="flex flex-col md:flex-row w-full md:h-screen gap-4 items-center justify-center bg-darkGreenGradient">
    <div class="flex w-full md:w-3/5 p-4 items-center justify-center overflow-y-hidden"> 
        <div class="flex flex-col w-full h-full p-16 items-center justify-center 3xl:gap-16 xl:gap-8 font-poppins shrink">
            <div class="flex flex-col items-start justify-start gap-4">
                <!-- <img src="../../../assets/WelcomePicture.png"/> -->
                <!-- <p class="text-primaryWhite font-bold 3xl:text-[148px] 2xl:text-[128px] 2xl:text-[90px] lg:text-[82px] md:text-[76px] sm:text-[64px] xs:text-[54px] 
                        3xl:leading-[140px] 2xl:leading-[120px] 2xl:leading-[82px] lg:leading-[74px] md:leading-[68px] sm:leading-[56px] xs:leading-[54px]">Welcome to <br> <span class="text-lightGreen">Firme</span> Générale</p> -->
                <!-- <p class="text-primaryWhite font-light 3xl:text-[24px] 2xl:text-[20px] 2xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-pretty">Streamline the management of your organization with Firme Générale, the ultimate platform for overseeing projects, 
                    employees, and financials efficiently. Our user-friendly interface provides you with powerful tools to enhance 
                    collaboration, optimize resources, and gain comprehensive insights—all in one place. 
                    <br>Start navigating your organizational success with us today.
                </p> -->
                <p class="text-primaryWhite font-light 3xl:text-[40px] 2xl:text-[32px] 2xl:text-[32px] xl:text-[18px] lg:text-[18px] md:text-[14px] sm:text-[12px] text-pretty">Streamline the management of your organization with Firme Générale, the ultimate platform for overseeing projects, 
                    employees, and financials efficiently. Our user-friendly interface provides you with powerful tools to enhance 
                    collaboration, optimize resources, and gain comprehensive insights—all in one place. 
                    <br>Start navigating your organizational success with us today.
                </p>
            </div>
            <div class="flex flex-col items-start justify-start gap-16 w-full shrink">
                <p class="text-primaryWhite font-medium text-[24px] tracking-wider">
                    OUR KEY FEATURES:
                </p>
                <div class="flex flex-row h-auto w-full items-center relative">
                    <div class="flex flex-col 2xl:size-[100px] xl:size-[80px] xs:size-[60px] items-center justify-center bg-lightGreen rounded-full relative z-[2]">
                        <img src="../../../assets/icons/ProjectsLandingIcon.png" class="xs:size-[20px] xl:size-[35px] 2xl:size-[50px] nav_menu_icon"/>
                    </div>
                    <div class="flex flex-col w-auto py-2.5 pl-8 pr-16 bg-primaryWhite/20 rounded-r-full absolute 2xl:ml-20 xl:ml-14 xs:ml-8 z-[1]">
                        <p class="2xl:text-[24px] xl:text-[20px] md:text-[14px] xs:text-[12px] font-medium text-primaryWhite">Project management</p>
                        <p class="2xl:text-[16px] xl:text-[11px] md:text-[10px] xs:text-[7px] font-light text-primaryWhite">
                            Coordinate all your projects seamlessly. Organize project structure, track progress, and meet deadlines with ease.              
                        </p>
                    </div>
                </div>
                <div class="flex flex-row w-full items-center relative">
                    <div class="flex flex-col 2xl:size-[100px] xl:size-[80px] xs:size-[60px] items-center justify-center bg-lightGreen rounded-full relative z-[2]">
                        <img src="../../../assets/icons/UsersLandingIcon.png" class="xs:size-[20px] xl:size-[35px] 2xl:size-[50px] nav_menu_icon"/>
                    </div>
                    <div class="flex flex-col w-auto py-2.5 pl-8 pr-16 bg-primaryWhite/20 rounded-r-full absolute 2xl:ml-20 xl:ml-14  xs:ml-8 z-[1]">
                        <p class="2xl:text-[24px] xl:text-[20px] md:text-[14px] xs:text-[12px] font-medium text-primaryWhite">Employees management</p>
                        <p class="2xl:text-[16px] xl:text-[11px] md:text-[10px] xs:text-[7px] font-light text-primaryWhite">
                            Optimize your workforce management. Track hours, assign tasks, and evaluate performance efficiently.             
                        </p>
                    </div>
                </div>
                <div class="flex flex-row w-full items-center relative">
                    <div class="flex flex-col 2xl:size-[100px] xl:size-[80px] xs:size-[60px] items-center justify-center bg-lightGreen rounded-full relative z-[2]">
                        <img src="../../../assets/icons/CashFlowLandingIcon.png" class="xs:size-[20px] xl:size-[35px] 2xl:size-[50px] nav_menu_icon"/>
                    </div>
                    <div class="flex flex-col w-auto py-2.5 pl-8 pr-16 bg-primaryWhite/20 rounded-r-full absolute 2xl:ml-20 xl:ml-14  xs:ml-8 z-[1]">
                        <p class="2xl:text-[24px] xl:text-[20px] md:text-[14px] xs:text-[12px] font-medium text-primaryWhite">Cashflow</p>
                        <p class="2xl:text-[16px] xl:text-[11px] md:text-[10px] xs:text-[7px] font-light text-primaryWhite">
                            Monitor your financials accurately. Track income, manage expenses, and forecast future cash flow with clarity.            
                        </p>
                    </div>
                </div>
            </div>
        </div>    
    </div>
    <div class="flex w-full md:w-2/5 items-center h-full bg-primaryWhite justify-center overflow-y-auto">
        @if (signIn) {
            <app-sign-in class="w-full"
                (signUp)="navigateToSignUp()"
                [displayedAsPage]="false"
            />
        }
        @if (signUp) {
            <app-sign-up class="w-full h-full z-[50]"
                (signIn)="navigateToSignIn()"
            />
        }
    </div>
</div>