import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TechStackItemComponent } from '../tech-stack-item/tech-stack-item.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddSkillModalComponent } from '../modals/add-skill-modal/add-skill-modal.component';
import { NgToastService } from 'ng-angular-popup';
import { ErrorResponse } from '../../dtos/ErrorResponse';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';
import { EmployeeService } from '../../services/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdateSkillModalComponent } from '../modals/update-skill-modal/update-skill-modal.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-tech-stack-and-experience',
  standalone: true,
  imports: [TechStackItemComponent, CommonModule],
  templateUrl: './tech-stack-and-experience.component.html',
  styleUrl: './tech-stack-and-experience.component.css'
})
export class TechStackAndExperienceComponent implements OnInit{
  
  @Input() employeeId : string = '';
  @Input() skills : any[] = []

  @Output() techStackAndExpLevelEdited = new EventEmitter<string>();

  public displayData : any[] = [];

  public tmpSkill : string = '';
  public tmpLevel : string = '';

  public editing : boolean = false;
  public requiresEditing : boolean = false;

  constructor(private modalService : NgbModal,
    private toast : NgToastService,
    private employeeService : EmployeeService,
  ) {}

  ngOnInit(): void {
    this.getDisplayData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['skills']) {
      this.getDisplayData();
    }
  }

  toggleEdit(): void {
    this.editing = !this.editing;
  }


  public getDisplayData() {
    this.displayData = []
    const techsString = sessionStorage.getItem('techStack');
    const expLevelsString = sessionStorage.getItem('experienceLevels');

    if(techsString && expLevelsString){
      const techStack = JSON.parse(techsString);
      const experienceLevels = JSON.parse(expLevelsString);
      
      this.requiresEditing = this.skills?.some((skill: { Level: string; }) => skill.Level === "000000000000000000000000");      
      
      this.skills.map(skill => {
        this.tmpLevel = '';
        this.tmpSkill = '';

        techStack.map((tech: { ID: any; Name : string; }) => {
          if(tech.ID === skill.Skill){
            this.tmpSkill = tech.Name;
          }
        });

        experienceLevels.map((level: { ID: any; Name: string; }) => {
          if(level.ID === skill.Level) {
            if(skill.Level != "000000000000000000000000"){
              this.tmpLevel = level.Name;
            }
          }
        })
        
        const body = {
          skillId : skill.ID,
          skillName : this.tmpSkill,
          levelName : this.tmpLevel,
        }
        this.displayData.push(body);
      });

      console.log("DATA: ", this.displayData);
    }
  }

  public openAddSkillModal() {
    const modalRef = this.modalService.open(
      AddSkillModalComponent,
      {backdrop : 'static', keyboard : true}
    )
    modalRef.componentInstance.existingEmployee = true;
    modalRef.componentInstance.employeeId = this.employeeId;
    modalRef.componentInstance.addedSkills = this.skills;
    modalRef.componentInstance.skillAdded.subscribe(
      (res : any) => {
        this.techStackAndExpLevelEdited.emit("Skills");
        this.toggleEdit();
      },
      (error : ErrorResponse) => {
        console.log(error.Message);
        this.toast.danger("Error while adding new skill!", "Error!", 3000);
      }
    )
    
  }

  public deleteSkill(selectedSkillId : string) {
    const modalRef = this.modalService.open(
      WarningModalComponent,
      { backdrop : 'static', keyboard : true }
    )

    modalRef.componentInstance.confirmation.subscribe(
      (res : any) => {
        this.employeeService.removeSkill(this.employeeId, selectedSkillId).subscribe(
          (res : any) => {
            this.techStackAndExpLevelEdited.emit("Skills");
            this.toggleEdit();
          },
          (error : HttpErrorResponse) => {
            console.log("Error while deleting skill, ", error.message);
            this.toast.danger("Error while deleting skill.", "Error!", 3000)
          }
        )
      }, (error : ErrorResponse) => {
        console.log("Error: ", error.Message);
      }
    )
  }

  public editSkill(skillId : string, techName : string) {
    const modalRef = this.modalService.open(
      UpdateSkillModalComponent,
      { backdrop : 'static', keyboard : true}
    );

    modalRef.componentInstance.skillId = skillId;
    modalRef.componentInstance.selectedTech = techName;
    modalRef.componentInstance.employeeId = this.employeeId;

    modalRef.componentInstance.skillUpdated.subscribe(
      (res : any) => {
        this.techStackAndExpLevelEdited.emit("Skills");
        this.toggleEdit();
        this.getDisplayData();
      },
      (error : ErrorResponse) => {
        console.log(error.Message);
      }
    )
  }

}
